import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { FirebaseAuth } from "../FirebaseAuth/firebase";
import firebase from "firebase/app";
import { userSignIn } from "../../libs/user";
import { RemoteConfigContext } from "../FirebaseAuth/RemoteConfigContext";
import {} from "./firebaseui.css"

const FirebaseUI = () => {
  const [signInSuccess, setSignInSuccess] = useState(null);

  const { flags } = useContext(RemoteConfigContext);

  // Configure FirebaseUI.
  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        userSignIn(flags, (result) => {
          if (result) {
            setSignInSuccess(true);
          } else {
            setSignInSuccess(false);
          }
        });
        return false;
      },
      uiShown: function () {
        document.getElementById("loader").style.display = "none";
      },
    },
    signInSuccessUrl: "/home",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
  };

  return (flags ?
    <>
      {signInSuccess && <i className="fa fa-spinner fa-5x fa-spin" />}
      {signInSuccess === null && (
        <>
          <div id="sign-in" className="SignIn">
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={FirebaseAuth.auth()}
            />
          </div>
          <div id="loader">
            <i className="fa fa-spinner fa-5x fa-spin" />
          </div>
        </>
      )}
      {signInSuccess === false && (
        <div className="text-center">
          <i className="fa fa-5x fa-fire text-warning"></i>
          <h1>Server Error</h1>
          <p>Oops, something went wrong, please try again.</p>
          <Link to="/home">Home</Link>
        </div>
      )}
    </> : null
  );
};

export default FirebaseUI;
