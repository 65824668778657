export const ACCOUNT_PLANS = {
  starter: "Starter",
  economy: "Economy",
  standard: "Standard",
  pro: "Pro",
};

export const CLUSTER_STATUSES = [
  {
    key: "NEW",
    progress: 25,
    icon: null,
    dbDefaultValue: true,
    faIcon: (
      <i className="fa fa-exclamation-circle" style={{ color: "black" }}></i>
    ),
  },
  {
    key: "INPROGRESS",
    progress: 35,
    icon: (
      <svg
        id="loader-1"
        x="0px"
        y="0px"
        width="90px"
        height="90px"
        viewBox="0 0 50 50"
      >
        <path
          fill="#FF6700"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    ),
    faIcon: <i className="fa fa-spinner" style={{ color: "orange" }}></i>,
    message: "Creating cluster in cloud...",
    subtext: "This process might take a few minutes to complete",
  },
  {
    key: "READY",
    progress: 100,

    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="96"
        height="96"
        viewBox="0 0 48 48"
        style={{ fill: "#000" }}
      >
        <path
          fill="#4caf50"
          d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
        ></path>
        <path
          fill="#ccff90"
          d="M34.602,14.602L21,28.199l-5.602-5.598l-2.797,2.797L21,33.801l16.398-16.402L34.602,14.602z"
        ></path>
      </svg>
    ),
    faIcon: <i className="fa fa-check-circle" style={{ color: "green" }}></i>,
    message: "Cluster Created Successfully ...",
    subtext: "This cluster will be automatically deleted after ",
  },
  {
    key: "FAILED",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="96"
        height="96"
        viewBox="0 0 50 50"
        style={{ fill: "red" }}
      >
        <path d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"></path>
      </svg>
    ),
    faIcon: <i className="fa fa-times-circle" style={{ color: "red" }}></i>,
    message: "Error creating cluster",
    subtext: "Please try again later",
  },
  {
    key: "NAME_ERROR",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="96"
        height="96"
        viewBox="0 0 50 50"
        style={{ fill: "red" }}
      >
        <path d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"></path>
      </svg>
    ),
    faIcon: <i className="fa fa-times-circle" style={{ color: "red" }}></i>,
    message: "Error creating cluster",
    subtext: "Cluster name already exists!",
  },
  {
    key: "QUOTA_LIMIT",
    icon: (
      <svg
        x="0px"
        y="0px"
        width="80"
        height="80"
        viewBox="0 0 226 226"
        style={{ fill: "#000000" }}
      >
        <g
          fill="none"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
        >
          <path d="M0,226v-226h226v226z" fill="none" stroke="none"></path>
          <g stroke="none">
            <path
              d="M113,217.525c-57.63565,0 -104.525,-46.88935 -104.525,-104.525c0,-57.63565 46.88935,-104.525 104.525,-104.525c57.63565,0 104.525,46.88935 104.525,104.525c0,57.63565 -46.88935,104.525 -104.525,104.525z"
              fill="#e74c3c"
            ></path>
            <path
              d="M113,11.3c56.07625,0 101.7,45.62375 101.7,101.7c0,56.07625 -45.62375,101.7 -101.7,101.7c-56.07625,0 -101.7,-45.62375 -101.7,-101.7c0,-56.07625 45.62375,-101.7 101.7,-101.7M113,5.65c-59.28545,0 -107.35,48.06455 -107.35,107.35c0,59.28545 48.06455,107.35 107.35,107.35c59.28545,0 107.35,-48.06455 107.35,-107.35c0,-59.28545 -48.06455,-107.35 -107.35,-107.35z"
              fill="#c74343"
            ></path>
            <path
              d="M129.95,113h16.95v-53.675c0,-4.6782 -3.7968,-8.475 -8.475,-8.475v0c-4.6782,0 -8.475,3.7968 -8.475,8.475zM84.75,113h16.95v-64.975c0,-4.6782 -3.7968,-8.475 -8.475,-8.475v0c-4.6782,0 -8.475,3.7968 -8.475,8.475zM62.15,129.95h16.95v-59.325c0,-4.6782 -3.7968,-8.475 -8.475,-8.475v0c-4.6782,0 -8.475,3.7968 -8.475,8.475zM107.35,113h16.95v-70.625c0,-4.6782 -3.7968,-8.475 -8.475,-8.475v0c-4.6782,0 -8.475,3.7968 -8.475,8.475zM121.7801,155.75355l16.26635,16.26635l39.38615,-39.38615c4.49175,-4.49175 4.49175,-11.7746 0,-16.26635v0c-4.49175,-4.49175 -11.7746,-4.49175 -16.26635,0z"
              fill="#ffffff"
            ></path>
            <path
              d="M116.8307,180.8h-26.4307c-15.59965,0 -28.25,-12.65035 -28.25,-28.25v-50.85h84.75v49.0307c0,16.60535 -13.46395,30.0693 -30.0693,30.0693z"
              fill="#ffffff"
            ></path>
          </g>
          <g stroke="none">
            <g id="Layer_1">
              <g>
                <circle
                  cx="31.17699"
                  cy="31"
                  transform="scale(5.65,5.65)"
                  r="8.5"
                  fill="#bae0bd"
                ></circle>
                <g fill="#5e9c76">
                  <path d="M176.15,129.95c24.86,0 45.2,20.34 45.2,45.2c0,24.86 -20.34,45.2 -45.2,45.2c-24.86,0 -45.2,-20.34 -45.2,-45.2c0,-24.86 20.34,-45.2 45.2,-45.2M176.15,124.3c-28.25,0 -50.85,22.6 -50.85,50.85c0,28.25 22.6,50.85 50.85,50.85c28.25,0 50.85,-22.6 50.85,-50.85c0,-28.25 -22.6,-50.85 -50.85,-50.85z"></path>
                </g>
              </g>
              <path
                d="M176.15,203.4c-9.605,0 -16.95,-7.345 -16.95,-16.95h11.3c0,3.39 2.26,5.65 5.65,5.65c3.39,0 5.65,-2.26 5.65,-5.65c0,-2.26 -1.695,-4.52 -3.955,-5.085l-6.78,-1.695c-6.78,-2.26 -11.865,-8.475 -11.865,-15.82c0,-9.04 7.345,-16.95 16.95,-16.95c9.605,0 16.95,7.345 16.95,16.95h-11.3v0c0,-2.825 -2.26,-5.65 -5.65,-5.65c-3.39,0 -5.65,2.26 -5.65,5.65c0,2.26 1.695,4.52 3.955,5.085l6.78,1.695c6.78,2.26 11.865,8.475 11.865,15.82c0,9.605 -7.345,16.95 -16.95,16.95z"
                fill="#5e9c76"
              ></path>
              <rect
                x="30.17699"
                y="25"
                transform="scale(5.65,5.65)"
                width="2"
                height="1.5"
                fill="#5e9c76"
              ></rect>
              <rect
                x="30.17699"
                y="35.5"
                transform="scale(5.65,5.65)"
                width="2"
                height="1.5"
                fill="#5e9c76"
              ></rect>
            </g>
          </g>
          <path
            d="M125.3,226v-101.7h101.7v101.7z"
            id="overlay-drag"
            fill="#ff0000"
            stroke="none"
            opacity="0"
          ></path>
        </g>
      </svg>
    ),
    faIcon: <i className="fa fa-ban" style={{ color: "red" }}></i>,
    message: "Cannot create cluster",
    subtext: "You have exceeded your subscription quota limit",
  },
  {
    key: "FETCHING_KUBECONFIG",
    faIcon: <i className="fa fa-spinner fa-1x fa-spin" style={{ color: "green" }}></i>,
    progress: 65,
  },
];

export const CLUSTER_VERSIONS = [
  { value: "v1.31.1-k3s1" },
  { value: "v1.30.5-k3s1" },
  { value: "v1.29.9-k3s1" },
  { value: "v1.28.14-k3s1" },
  { value: "v1.27.1-k3s1", default: true },
  { value: "v1.26.5-k3s1" },
  { value: "v1.25.10-k3s1" },
  { value: "v1.24.6-k3s1" },
  { value: "v1.23.12-k3s1" },
  { value: "v1.22.15-k3s1" },
];
export const ADDON_CATEGORIES = [
  { value: "Security" },
  { value: "Monitoring / Logging" },
  { value: "Management", default: true },
  { value: "CICD" },
  { value: "Storage" },
  { value: "Database" },
  { value: "Low code/No code" },
  { value: "Popular" },
  { value: "Architecture" },
  { value: "Api Gateway" },
  { value: "Observability" },
];
export const EXPIRY_OPTIONS = {
  ALL: [{ label: "7 Days", value: 7, format: "DAYS", default: true }],
  ALL_PAID: [
    { label: "10 Mins", value: 10, format: "MINS" },
    { label: "30 Mins", value: 30, format: "MINS" },
    { label: "2 Hrs", value: 2, format: "HOURS" },
    { label: "24 Hrs", value: 24, format: "HOURS" },
    { label: "15 Days", value: 15, format: "DAYS" },
    { label: "30 Days", value: 30, format: "DAYS" },
    { label: "60 Days", value: 60, format: "DAYS" },
    { label: "90 Days", value: 90, format: "DAYS" },
    { label: "Never", value: 0, format: "NEVER" },
  ],
  PER_PLAN: [
    {
      plan: ACCOUNT_PLANS["starter"],
      options: [
        { label: "30 Mins", value: 30, format: "MINS" },
        { label: "24 Hrs", value: 24, format: "HOURS" },
      ],
    },
    {
      plan: ACCOUNT_PLANS["economy"],
      options: [],
    },
    {
      plan: ACCOUNT_PLANS["standard"],
      options: [],
    },
    {
      plan: ACCOUNT_PLANS["pro"],
      options: [],
    },
  ],
};

export const RESOURCES_FOR_PLAN = {
  Starter: {
    CPU: 2,
    RAM: 4,
    HDD: 40,
  },
  Economy: {
    CPU: 4,
    RAM: 8,
    HDD: 80,
  },
  Standard: {
    CPU: 8,
    RAM: 16,
    HDD: 160,
  },
  Pro: {
    CPU: 16,
    RAM: 32,
    HDD: 320,
  },
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addHours = (date, hours) => {
  var result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
};

export const addMinutes = (date, mins) => {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() + mins);
  return result;
};
