import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbContext } from "../../../../../components/Breadcrumb";
import { AuthContext } from "../../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../../components/FirebaseAuth/firebase";
import { Field, Form, Input } from "../../../../../components/Form";
import HelmInstructions from "./HelmInstructions";

const AddDataCenter = () => {
  const title = "Create Custom Data Center";

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { authUser } = useContext(AuthContext);

  const [inSubmit, setInSubmit] = useState(false);
  const [helmInstructions, setHelmInstructions] = useState({
    show: false,
    datacenter: null,
  });

  const [dcName, setDcName] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [domainName, setDomainName] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [appsdomain, setAppsDomain] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [externalIP, setExternalIP] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Home",
        active: false,
      },
      {
        to: "/datacenter/create",
        text: "User",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setHelmInstructions({ show: false, datacenter: null });
    setInSubmit(true);
    const createDataCenter = CloudFunctions.httpsCallable("createDataCenter");
    createDataCenter({
      name: dcName.value,
      domain: domainName.value,
      appsdomain: appsdomain.value,
      externalIP: externalIP.value,
      userId: authUser.user.uid,
    })
      .then((response) => {
        // const accountId = response.data.accountId;
        // setRedirect("/home");
        setHelmInstructions({
          show: true,
          datacenter: (response.data.status && response.data.dc) || null,
        });
        setInSubmit(false);
      })
      .catch((err) => {
        setInSubmit(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <div className="text-right mb-3">
              <Link to="view" className="btn btn-primary mr-2">
                <i className="fa fa-arrow-left"></i> View Datacenters
              </Link>
              <Link to="/home" className="btn btn-primary">
                <i className="fa fa-arrow-right"></i> Goto Accounts
              </Link>
            </div>
            <Form
              handleSubmit={(e) => handleFormSubmit(e)}
              disabled={
                dcName.value === null ||
                domainName.value === null ||
                appsdomain.value === null ||
                externalIP.value === null ||
                inSubmit ||
                dcName.hasError ||
                domainName.hasError ||
                appsdomain.hasError ||
                externalIP.hasError
              }
              inSubmit={inSubmit}
              enableDefaultButtons={true}
            >
              <Field label="Datacenter Name">
                <Input
                  id="dcName"
                  type="text"
                  name="datacenter-name"
                  maxLen={100}
                  required={true}
                  changeHandler={setDcName}
                  validRegex="^[A-Za-z](?!.*--)(?!.*__)(?!.*-_)(?!.*_-)[A-Za-z0-9_-]*$"
                />
              </Field>
              <Field label="Sub-Domain Name">
                <Input
                  id="domainName"
                  type="text"
                  name="domain-name"
                  maxLen={100}
                  required={true}
                  changeHandler={setDomainName}
                />
              </Field>
              <Field label="Apps Sub-Domain Name">
                <Input
                  id="appsdomain"
                  type="text"
                  name="apps-domain-name"
                  maxLen={100}
                  required={true}
                  changeHandler={setAppsDomain}
                />
              </Field>
              <Field label="External IP Address">
                <Input
                  id="externalIP"
                  type="text"
                  name="external-ip"
                  maxLen={100}
                  required={true}
                  changeHandler={setExternalIP}
                  validRegex="^([1-9]\d{0,2}|1\d{2}|2[0-4]\d|25[0-5])(\.([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])){3}$"
                />
              </Field>
            </Form>
          </div>
          {helmInstructions.show && (
            <HelmInstructions instructions={helmInstructions} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddDataCenter;
