import { CCallout, CCol, CRow, CSwitch } from "@coreui/react";
import React, { useState } from "react";
import CustomModal from "../../../../../components/Modal";
import HelmInstructions from "../AddDataCenter/HelmInstructions";

const Credentials = ({ datacenter }) => {
  const [showCredentials, setShowCredentials] = useState(false);
  const [helmInstructions, setHelmInstructions] = useState({
    show: false,
    errorMessage: null,
  });
  const [showModal, setShowModal] = useState({
    open: false,
  });

  const toggleCredentials = () => {
    setShowCredentials(!showCredentials);
  };

  const showInstructionsDialog = () => {
    setHelmInstructions({
      show: true,
      errorMessage: null,
    });
    setShowModal({ open: true });
  };

  const dismissInstructionsDialog = (data) => {
    setHelmInstructions({
      show: false,
      errorMessage: null,
    });

    setShowModal(data);
  };

  return (
    <>
      <CRow className="px-4 py-2">
        <CCol col="12" sm="2" className="d-flex flex-column p-2">
          <small className="text-muted">Click to View</small>
          {/* eslint-disable */}
          <a href="#" className="underline" onClick={showInstructionsDialog}>
            <u>Helm Instructions</u>
          </a>
        </CCol>
        <CCol col="12" sm="3" className="d-flex flex-column">
          <small className="text-muted pb-2">Toggle credentials</small>
          <CSwitch
            color="primary"
            checked={showCredentials}
            value={"danger"}
            variant="3d"
            onChange={toggleCredentials}
          />
        </CCol>
        {showCredentials && (
          <>
            <CCol col="12" sm="4" className="d-flex align-items-center">
              <CCallout color={"danger"} className={"bg-white my-1 pr-2"}>
                <small className="text-muted">Cluster Username</small>
                <br />
                <h6>{datacenter.username || ""}</h6>
              </CCallout>
            </CCol>
            <CCol col="12" sm="3" className="d-flex align-items-center">
              <CCallout color={"danger"} className={"bg-white my-1 pr-2"}>
                <small className="text-muted">Cluster Password</small>
                <br />
                <h6>{datacenter.password || ""}</h6>
              </CCallout>
            </CCol>
          </>
        )}
      </CRow>
      {helmInstructions.show && datacenter && (
        <CustomModal
          visible={showModal.open}
          setVisible={dismissInstructionsDialog}
          header={`Instructions for Helm Install`}
          content={
            <HelmInstructions
              instructions={{ datacenter: datacenter }}
              requestFromDCTable={true}
            />
          }
        />
      )}
    </>
  );
};

export default Credentials;
