import { CCallout, CCol, CCollapse, CDataTable, CRow } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbContext } from "../../../../../components/Breadcrumb";
import { AuthContext } from "../../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../../components/FirebaseAuth/firebase";
import CustomModal from "../../../../../components/Modal";
import { IsLoginUserFromZelarSoft } from "../../../../../libs/helper";
import Credentials from "./Credentials";

const fields = [
  {
    key: "show_details",
    label: "",
    _style: { width: "1%" },
    sorter: false,
    filter: false,
  },
  { key: "name", label: "Datacenter Name", _style: { width: "max-content" } },
  { key: "managed", label: "Managed By", _style: { width: "10%" } },
  { key: "subDomain", label: "Sub Domain", _style: { width: "35%" } },
  { key: "appsSubDomain", label: "Apps Sub Domain", _style: { width: "40%" } },
  { key: "action", _style: { width: "max-content" } },
  //   { key: "cidr", label: "CIDR", _style: { width: "max-content" } },
  //   { key: "externalIP", label: "External IP", _style: { width: "max-content" } },
];

const ViewDataCenter = () => {
  const title = "View data centers";

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { authUser } = useContext(AuthContext);

  const [myDatacenters, setMyDatacenters] = useState([]);
  const [details, setDetails] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    datacenter: null,
    errorMessage: null,
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
  });

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Home",
        active: false,
      },
      {
        to: "/datacenter/view",
        text: "User",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  const showDeleteAlert = (dc) => {
    setConfirmDelete({
      show: true,
      datacenter: dc,
      errorMessage: null,
    });
    setShowDeleteModal({ open: true });
  };

  const dismissDeleteAlert = (data) => {
    setConfirmDelete({
      show: false,
      datacenter: null,
      errorMessage: null,
    });

    setShowDeleteModal(data);
  };

  const deleteDCFromView = async (dcID) => {
    setMyDatacenters(myDatacenters.filter((x) => x.id !== dcID));
  };

  const continueDeleteDatacenter = () => {
    if (confirmDelete.datacenter) {
      setShowSpinner(true);
      const datacenterRef = FirebaseAuth.firestore().collection("datacenters");
      datacenterRef
        .doc(confirmDelete.datacenter.id)
        .delete()
        .then(() => {
          deleteDCFromView(confirmDelete.datacenter.id);
          dismissDeleteAlert();
        })
        .catch((err) => {
          setConfirmDelete({ ...confirmDelete, errorMessage: err.message });
          console.log(err.message);
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  };

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [details, index];
    }
    setDetails(newDetails);
  };
  /* eslint-disable */
  useEffect(() => {
    async function getDatacenters() {
      const datacenterRef = FirebaseAuth.firestore().collection("datacenters");
      const userdatacenters = await datacenterRef
        .where("owner", "==", authUser.user.uid)
        .where("managed", "==", "CUSTOMER")
        .get();
      if (userdatacenters && userdatacenters.size > 0) {
        setMyDatacenters((prevState) => [
          ...prevState,
          ...userdatacenters.docs.map((x) => x.data()),
        ]);
      }
      if (IsLoginUserFromZelarSoft()) {
        const kntsdatacenters = await datacenterRef
          .where("managed", "==", "KNTS")
          .get();
        if (kntsdatacenters && kntsdatacenters.size > 0) {
          setMyDatacenters((prevState) => [
            ...prevState,
            ...kntsdatacenters.docs.map((x) => x.data()),
          ]);
        }
      }
    }
    authUser.user?.uid && getDatacenters();
  }, []);
  /* eslint-enable */

  return (
    <div className="container-fluid">
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <div className="text-right mb-3">
              <Link to="/home" className="btn btn-primary mr-2">
                <i className="fa fa-arrow-right"></i> Goto Accounts
              </Link>
              <Link to="create" className="btn btn-primary">
                <i className="fa fa-plus"></i> Add Datacenter
              </Link>
            </div>
            <CDataTable
              items={myDatacenters}
              fields={fields}
              columnFilter
              tableFilter
              footer={false}
              itemsPerPageSelect
              itemsPerPage={5}
              sorter
              pagination
              scopedSlots={{
                managed: (item) => (
                  <td>{item.managed === "CUSTOMER" ? "You" : "Zelar"}</td>
                ),
                show_details: (item, index) => {
                  return (
                    <td className="py-2 expandIcon">
                      <i
                        className={`fa ${
                          details.includes(index)
                            ? "fa-minus-square"
                            : "fa-plus-square"
                        } fa-lg p-2`}
                        onClick={() => {
                          toggleDetails(index);
                        }}
                      ></i>
                    </td>
                  );
                },
                action: (item) => (
                  <td>
                    {item.managed === "CUSTOMER" && (
                      <button
                        id="btnDeleteDC"
                        className="btn btn-sm btn-ghost-danger d-flex"
                        onClick={() => showDeleteAlert(item)}
                      >
                        <i className="fa fa-trash mr-2"></i> {`Delete`}
                      </button>
                    )}
                  </td>
                ),
                details: (item, index) => {
                  return (
                    <CCollapse show={details.includes(index)} key={index}>
                      <CRow className="px-4 py-2">
                        <CCol
                          col="12"
                          sm="3"
                          className="d-flex align-items-center"
                        >
                          <CCallout
                            color={"warning"}
                            className={"bg-white my-1 pr-2"}
                          >
                            <small className="text-muted">Provider</small>
                            <br />
                            <h6>{item.provider}</h6>
                          </CCallout>
                        </CCol>
                        <CCol
                          col="12"
                          sm="3"
                          className="d-flex align-items-center"
                        >
                          <CCallout
                            color={"success"}
                            className={"bg-white my-1 pr-2"}
                          >
                            <small className="text-muted">CIDR</small>
                            <br />
                            <h6>{item.cidr}</h6>
                          </CCallout>
                        </CCol>
                        <CCol
                          col="12"
                          sm="3"
                          className="d-flex align-items-center"
                        >
                          <CCallout
                            color={"info"}
                            className={"bg-white my-1 pr-2"}
                          >
                            <small className="text-muted">
                              External IP Address
                            </small>
                            <br />
                            <h6>{item.externalIP || "-"}</h6>
                          </CCallout>
                        </CCol>
                      </CRow>
                      <Credentials datacenter={item} />
                    </CCollapse>
                  );
                },
              }}
            />
            {confirmDelete.show && confirmDelete.datacenter && (
              <CustomModal
                visible={showDeleteModal.open}
                setVisible={dismissDeleteAlert}
                header={`Confirm Delete`}
                content={
                  <>
                    <div className="d-flex align-items-baseline">
                      <i className="fa fa-exclamation-triangle pr-2 text-warning"></i>
                      <p>{`Are you sure you want to delete this datacenter : ${confirmDelete.datacenter.name} ?`}</p>
                    </div>
                    <p className="bg-danger text-center p-1">
                      <strong>
                        {`** Note: This will delete the linked accounts and their clusters as well`}
                      </strong>
                    </p>
                    {confirmDelete.errorMessage && (
                      <h6 className="text-danger">{`${confirmDelete.errorMessage} Please try again later.`}</h6>
                    )}
                    {showSpinner && (
                      <div className="spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Deleting...</span>
                        </div>
                      </div>
                    )}
                  </>
                }
                successText={`Delete`}
                onSuccess={continueDeleteDatacenter}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDataCenter;
