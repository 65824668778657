import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";

import FirebaseUI from "../../../components/FirebaseUI";
import { authService } from "../../../components/FirebaseAuth/firebase";

import Logo from "../../../assets/logo.png";

import "./SignIn.css";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import TermsAndConditions from "../TermsAndConditions";

const SignIn = () => {
  const [user, loading] = useAuthState(authService);
  const [visible, setVisible] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) history.replace("/home");
  }, [user,history,loading]);

  const backButton = () => {
    if (window.location.hostname === "dev-app.klusternetes.com") {
      window.location.href = "https://dev.klusternetes.com/";
    } else {
      window.location.href = "https://klusternetes.com/";
    }
  };
  return (
    <>
      <div className="sign-in">
        <div className="text-center col-md-6">
          <img className="logo" src={Logo} alt="logo" />
          <div className="card">
            <p></p>
            <i className="fa fa-5x  text-warning"></i>
            <h2 className="h3 mb-3 font-weight-normal">Welcome Back</h2>
            <h6>To continue, log in to KLUSTERNETES.</h6>
            <div className="card-body">
              <FirebaseUI />
            </div>
            <div className="card-footer">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked
                  readOnly
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  By using our site you agree to the following{" "}
                  <CButton color="link" onClick={() => setVisible(!visible)}>
                    Terms and Conditions
                  </CButton>
                </label>
              </div>
            </div>
          </div>
          <button className="btn mr-2 btn-primary landing" onClick={backButton}>
            Back to landing page
          </button>
        </div>
      </div>
      {visible && (
        <div>
          <CModal className={`termsModal show d-block`}>
            <CModalHeader>
              <CModalTitle>Terms and Conditions</CModalTitle>
            </CModalHeader>

            <CModalBody>
              <TermsAndConditions />
            </CModalBody>

            <CModalFooter>
              <CButton color="success" onClick={() => setVisible(false)}>
                Close
              </CButton>
            </CModalFooter>
          </CModal>
        </div>
      )}
    </>
  );
};

export default SignIn;
