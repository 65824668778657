import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react";
import React, { useState } from "react";
import { Field, Form } from "../../../../../components/Form";
import CustomModal from "../../../../../components/Modal";

const KubeConfigModal = ({ visible, setVisible, config }) => {
  const [activeKey, setActiveKey] = useState(2);
  const [LinuxCmnd] = useState(
    `\ncurl -sL "${config}" -o $(pwd)/kluster.yaml\nexport KUBECONFIG=$(pwd)/kluster.yaml\nkubectl get nodes\n`
  );
  const [WindowsCmnd] = useState(
    `\nwget "${config}" -o $pwd/kluster.yaml
$Env:KUBECONFIG="$pwd/kluster.yaml"
kubectl get nodes`
  );

  const copyToClipboard = (eleId) => {
    const el = document.getElementById(eleId);
    el?.select();
    document.execCommand("copy");
  };

  return (
    <CustomModal
      visible={visible}
      setVisible={setVisible}
      header={`Steps for connecting to the cluster`}
      content={
        <>
          {" "}
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                active={activeKey === 2}
                onClick={() => setActiveKey(2)}
              >
                linux
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                active={activeKey === 1}
                onClick={() => setActiveKey(1)}
              >
                windows
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              active={activeKey === 1}
              aria-labelledby="overview-tab"
            >
              <Form>
                <Field>
                  <textarea
                    id="kubeconfigWindows"
                    className="form-control"
                    placeholder="Code Block"
                    readOnly={true}
                    value={WindowsCmnd}
                  />
                </Field>
              </Form>
            </CTabPane>

            <CTabPane
              role="tabpanel"
              active={activeKey === 2}
              aria-labelledby="addons-tab"
            >
              <Form>
                <Field>
                  <textarea
                    id="kubeconfigLinux"
                    className="form-control"
                    placeholder="Code Block"
                    readOnly={true}
                    value={LinuxCmnd}
                  />
                </Field>
              </Form>
            </CTabPane>
          </CTabContent>
        </>
      }
      successText={`Copy To Clipboard`}
      onSuccess={
        activeKey == 1
          ? () => copyToClipboard("kubeconfigWindows")
          : () => copyToClipboard("kubeconfigLinux")
      }
    />
  );
};

export default KubeConfigModal;
