import React from 'react';
import styled, { keyframes } from 'styled-components';

const blinkingColor = '#007AFF';

const ProgressContainer = styled.div`
  height: 2px;
  width: 100%;
  background-color: #F0F0F0;
  border-radius: 1px;
  overflow: hidden;
`;

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    background-color: ${blinkingColor};
  }
  100% {
    transform: translateX(100%);
  }
`;

const Progress = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${blinkingColor};
  transform: translateX(-100%);
  animation: ${animation} 1s linear infinite;
`;

const Loading = () => {
  return (
    <ProgressContainer>
      <Progress />
    </ProgressContainer>
  );
};

export default Loading;
