import {
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { FirebaseAuth } from "../../../../../components/FirebaseAuth/firebase";
import ConfigurationModal from "./MarketPlace/ConfigurationModal";
import warning from "../../../../../assets/images/warning.png";
import { AuthContext } from "../../../../../components/FirebaseAuth";
import CustomModal from "../../../../../components/Modal";
import { Field, Input } from "../../../../../components/Form";

const Features = ({
  selectedFeatures,
  selectedFeatureInputs,
  toggleFeatureSelection,
  formValues,
  existedFeatures,
  calledFrom,
}) => {
  const { userData } = useContext(AuthContext);

  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  const [searchAddon, setSearchAddon] = useState("");
  const [activeTab, setActiveTab] = useState("Popular");
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    errorMessage: null,
  });

  const [editingModal, setEditingModal] = useState({
    visible: false,
    option: null,
  });

  const dismissDeleteAlert = (data) => {
    setConfirmDelete({
      show: false,
      errorMessage: null,
    });

    setShowDeleteModal(data);
  };

  const showDeleteAlert = (addon) => {
    setConfirmDelete({
      show: true,
      errorMessage: null,
      addon: addon,
    });
    setShowDeleteModal({ open: true });
  };

  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
  });

  const queryMarketplace = () => {
    const marketplaceRef = FirebaseAuth.firestore().collection("marketplace");
    const unsub = marketplaceRef.onSnapshot((querySnapshot) => {
      setMarketplaceOptions([]);

      querySnapshot.forEach((option) => {
        if (option.data().showInMarketPlace) {
          setMarketplaceOptions((prevState) => [...prevState, option.data()]);
          if (option.data().defaultEnable) {
            toggleFeatureSelection(option.data().id);
          }
        }
      });
    });
  };

  const editConfigHandler = (e, option) => {
    e.preventDefault();
    e.stopPropagation(); // To avoid clicking the card and just handle the button click
    setEditingModal({ visible: true, option: option });
  };

  const closeModalHandler = () => {
    setEditingModal({
      visible: false,
      option: null,
    });
  };
  /* eslint-disable */
  useEffect(() => {
    queryMarketplace();
  }, []);

  const categoriesList = Object.entries(
    _.groupBy(marketplaceOptions, (opt) => opt.category)
  );
  const deleteCustomerAddon = async (delAddon) => {
    const marketplaceRef = FirebaseAuth.firestore().collection("marketplace");
    const marketplaceDocsToBeDeleted = await marketplaceRef
      .where("displayName", "==", delAddon)
      .get();
    marketplaceDocsToBeDeleted.docs.map((ele) =>
      marketplaceRef
        .doc(ele.id)
        .delete()
        .then(() => {
          console.log("customer Addon deleted successfully");
        })
    );
    dismissDeleteAlert();
  };
  useEffect(() => {
    let filteredCategories = categoriesList
      .filter(
        calledFrom == "myMarketPlace"
          ? (entry) =>
              entry[1].some(
                (obj) => obj?.owned_by === userData.currentAccount.id
              )
          : (entry) => entry[1].some((obj) => obj.hasOwnProperty("id"))
      )
      .filter((entry) =>
        entry[1].some((obj) =>
          searchAddon ? obj["id"].includes(searchAddon) : obj["id"] == obj["id"]
        )
      );
    setActiveTab(filteredCategories[0]?.[0]);
  }, [searchAddon]);

  return (
    <div className="featuresGrid">
      <CTabs activeTab={activeTab}>
        <br />
        <Field>
          <Input
            type="search"
            placeholder="search addon..."
            changeHandler={(e) => setSearchAddon(e.value.toLowerCase())}
          />
        </Field>
        <br />

        <CNav variant="tabs">
          {categoriesList
            .filter(
              calledFrom == "myMarketPlace"
                ? (entry) =>
                    entry[1].some(
                      (obj) => obj?.owned_by === userData.currentAccount.id
                    )
                : (entry) => entry[1].some((obj) => obj.hasOwnProperty("id"))
            )
            .filter((entry) =>
              entry[1].some((obj) =>
                searchAddon
                  ? obj["id"].toLowerCase().includes(searchAddon)
                  : obj["id"] == obj["id"]
              )
            )
            .map((entry, i) => {
              return (
                <CNavItem
                  key={i}
                  data-tab={entry[0]}
                  onClick={() => setActiveTab(entry[0])}
                >
                  <CNavLink active={activeTab === entry[0]}>
                    {entry[0]}

                    <CBadge
                      color="warning"
                      shape="pill"
                      style={{ marginLeft: "1em" }}
                    >
                      {categoriesList
                        .filter((x) => x[0] === entry[0])[0][1]
                        .map((x) => x.id)
                        .reduce(
                          (a, c) => a + selectedFeatures.includes(c),
                          0
                        ) || ""}
                    </CBadge>
                  </CNavLink>
                </CNavItem>
              );
            })}
        </CNav>
        <CTabContent className="marketplaceMainTabs">
          {categoriesList.map((entry, i) => {
            return (
              <CTabPane key={i} data-tab={entry[0]}>
                {!entry[1].filter((item) => !existedFeatures.includes(item.id))
                  .length && (
                  <center>
                    <img src={warning} width="100rem" alt="" />
                    <h4>No apps found!</h4>
                  </center>
                )}
                {entry[1]
                  .filter(
                    calledFrom == "myMarketPlace"
                      ? (item) =>
                          !existedFeatures.includes(item.id) &&
                          item?.owned_by === userData.currentAccount.id
                      : (item) =>
                          !existedFeatures.includes(item.id) && !item?.owned_by
                  )
                  .filter((item) => item.id.includes(searchAddon))
                  .map((option, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`card ${
                          selectedFeatures.includes(option.id)
                            ? "isSelected"
                            : ""
                        }`}
                        onClick={() =>
                          toggleFeatureSelection(option.id, option.defaultYaml)
                        }
                      >
                        <div className="card-header">
                          <img
                            src={option.icon}
                            style={{ height: 50, width: 50 }}
                            alt=""
                          />
                          <div>{option.displayName}
                          <h6>v{option?.version}</h6></div>
                        </div>

                        <div className="card-body">
                          <p className="card-text">
                            {parse(option.description)}...
                            <a
                              href={option.knowMore}
                              rel="noreferrer"
                              target="_blank"
                              style={{ color: "white" }}
                            >
                              More
                            </a>
                          </p>
                        </div>
                        <div className="card-footer d-flex">
                          {option.defaultYaml ? (
                            <button
                              className="btn btn-light"
                              onClick={(e) => editConfigHandler(e, option)}
                            >
                              Edit Configuration
                              <i className="fa fa-edit pl-2" />
                            </button>
                          ) : (
                            <p className="text-danger">No Configuration</p>
                          )}
                          {calledFrom == "myMarketPlace" && (
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                showDeleteAlert(option.displayName);
                              }}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </CTabPane>
            );
          })}
        </CTabContent>
      </CTabs>
      {editingModal.visible && (
        <ConfigurationModal
          feature={editingModal.option}
          visible={editingModal.visible}
          closeModalHandler={closeModalHandler}
          selectedFeatureInputs={selectedFeatureInputs}
          toggleFeatureSelection={toggleFeatureSelection}
          formValues={formValues}
        />
      )}
      {confirmDelete.show && (
        <CustomModal
          visible={showDeleteModal.open}
          setVisible={dismissDeleteAlert}
          header={`Confirm Delete`}
          content={
            <>
              <div className="d-flex align-items-baseline">
                <i className="fa fa-exclamation-triangle pr-2 text-warning"></i>
                <p>
                  Are you sure you want to delete this Addo-on :
                  <b> {confirmDelete.addon}</b>{" "}
                </p>
              </div>
              {confirmDelete.errorMessage && (
                <h6
                  style={{ color: "red" }}
                >{`${confirmDelete.errorMessage} Please try again later.`}</h6>
              )}
            </>
          }
          successText={`Delete`}
          onSuccess={() => deleteCustomerAddon(confirmDelete.addon)}
        />
      )}
    </div>
  );
};

export default Features;
