import React, { useState } from 'react'
import CustomModal from '../../../../../../components/Modal'
import EditConfiguration from './EditConfiguration'

const ConfigurationModal = ({ feature, visible, closeModalHandler, toggleFeatureSelection, selectedFeatureInputs, formValues }) => {
  const [editedYaml, setEditedYaml] = useState(selectedFeatureInputs.filter(x => Object.keys(x)[0] === feature.id)?.[0]?.[feature.id] || feature.defaultYaml);
  const [checkErr, setCheckErr] = useState(false);
  const [currentFeature, setCurrentFeature] = useState(formValues.filter(x => Object.keys(x)[0] === feature.id)?.[0]?.[feature.id] || {...feature});
  const updateConfigHandler = () => {
    toggleFeatureSelection(feature.id, editedYaml, currentFeature); 
    closeModalHandler();
  }

  return (
    editedYaml && <CustomModal
      visible={visible}
      setVisible={closeModalHandler}
      header={`Edit ${feature?.displayName} Configuration`}
      successText="Save Configuration"
      onSuccess={updateConfigHandler}
      buttonDisabled={checkErr}
      content={<EditConfiguration feature={feature}
        editedYaml={editedYaml}
        setEditedYaml={setEditedYaml} 
        setCheckErr={setCheckErr}
        currentFeature={currentFeature}
        setCurrentFeature={setCurrentFeature} />}
    />
  )
}

export default ConfigurationModal
