import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
import 'firebase/remote-config';
import config from "./firebase-config";

const FirebaseAuth = firebase.initializeApp(config);
const Analytics = firebase.analytics();
const Firestore = FirebaseAuth.firestore();
const CloudFunctions = FirebaseAuth.functions();
const authService = FirebaseAuth.auth();

const LogAnalyticsEvent = (event, params) => {
    Analytics.logEvent(event, params);
}

export { FirebaseAuth, Firestore, CloudFunctions, authService, LogAnalyticsEvent, firebase };
