import React, { useContext, useEffect, useState } from "react";
import { CDataTable, CCollapse, CProgress, CProgressBar, CTooltip } from "@coreui/react";
import {
  FirebaseAuth,
  LogAnalyticsEvent,
} from "../../../../../components/FirebaseAuth/firebase";
import { CLUSTER_STATUSES } from "../../../../../constants/clusters";
import KubeConfigModal from "./KubeConfigModal";
import CustomModal from "../../../../../components/Modal";
import ClusterDetailsPanel from "./ClusterDetailsPanel";
import { AuthContext } from "../../../../../components/FirebaseAuth";

const fields = [
  {
    key: "show_details",
    label: "",
    _style: { width: "1%" },
    sorter: false,
    filter: false,
  },
  { key: "name", _style: { width: "25%" } },
  { key: "status", _style: { width: "8%" } },
  { key: "createdDate", _style: { width: "25%" } },
  { key: "ExpiresIn", _style: { width: "25%" } },
  { key: "download", label: "Download Kube Config", _style: { width: "8%" } },
  { key: "action", _style: { width: "8%" } },
];

const ClustersView = ({ clusters, deleteClusterFromView }) => {
  const [details, setDetails] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    clusterId: null,
    clusterName: null,
    errorMessage: null,
  });
  const [showPopupModal, setShowPopupModal] = useState({
    open: false,
    data: null,
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
  });

  const [currentDatacenter, setCurrentDatacenter] = useState(null);

  const { userData } = useContext(AuthContext);
  const currentUser = FirebaseAuth.auth().currentUser;

  const openPopupModal = (thisConfig) => {
    setShowPopupModal({ open: true, data: thisConfig });
  };

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [details, index];
    }
    setDetails(newDetails);
  };

  const showDeleteAlert = (id, name) => {
    setConfirmDelete({
      show: true,
      clusterId: id,
      clusterName: name,
      errorMessage: null,
    });
    setShowDeleteModal({ open: true });
  };

  const dismissDeleteAlert = (data) => {
    setConfirmDelete({
      show: false,
      clusterId: null,
      clusterName: null,
      errorMessage: null,
    });

    setShowDeleteModal(data);
  };

  const continueDeleteCluster = () => {
    if (confirmDelete.clusterId) {
      setShowSpinner(true);
      const clustersRef = FirebaseAuth.firestore().collection("clusters");
      clustersRef
        .doc(confirmDelete.clusterId)
        .delete()
        .then(() => {
          deleteClusterFromView(confirmDelete.clusterId);
          dismissDeleteAlert();
          LogAnalyticsEvent("cluster_deleted", {
            clusterName: confirmDelete.clusterName,
            datacenter: userData.currentAccount.datacenter,
            accountId: userData.currentAccount.id,
            user_name: FirebaseAuth.auth().currentUser.displayName || "",
            user_email: FirebaseAuth.auth().currentUser.email || "",
          });
        })
        .catch((err) => {
          setConfirmDelete({ ...confirmDelete, errorMessage: err.message });
          console.log(err.message);
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  };

  const handleCopyToClipboard = (divId) => {
    const el = document.getElementById(divId);
    var temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = el.innerText;
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  };

  useEffect(() => {
    async function getDatacenter() {
      const datacenterRef = FirebaseAuth.firestore().collection("datacenters");
      const datacenter = await datacenterRef
        .where("name", "==", userData.currentAccount.datacenter)
        .get();
      if (datacenter && datacenter.size > 0) {
        setCurrentDatacenter(datacenter.docs[0]?.data());
      }
    }

    getDatacenter();
  }, [userData.currentAccount.datacenter]);

  return (
    <>
      <CDataTable
        items={clusters.sort((a,b)=>a.createdDate-b.createdDate)}
        fields={fields}
        tableFilter
        footer={false}
        itemsPerPage={5}
        sorter
        scopedSlots={{
          status: (item) => (
            <td>
              {
                CLUSTER_STATUSES.filter((x) => x.key === item.status)?.[0]
                  ?.faIcon
              }
            </td>
          ),
          createdDate: (item) => (
            <td>{new Date(item.createdDate).toLocaleString()}</td>
          ),
          ExpiresIn: (item) => (
            <td>
              {item.expiresIn_days}
              {item.expiresIn_days !== "never" && "days"}
              <CProgress className="mb-3">
                <CProgressBar
                  height="2rem"
                  color={
                    item.expiresIn_days >= 7
                      ? "success"
                      : item.expiresIn_days >= 4
                      ? "warning"
                      : item.expiresIn_days >= 1
                      ? "danger"
                      : "success"
                  }
                  value={
                    item.expiresIn_days !== "never" && item.expiresIn_days !== 0
                      ? (item.expiresIn_days / item.clusterValidity_days) * 100
                      : item.expiresIn_days !== 0
                      ? 100
                      : 0
                  }
                />
              </CProgress>
            </td>
          ),
          download: (item) => (
            <td>
              <button
                disabled={item.status !== "READY"}
                className="btn btn-square btn-info btn-sm d-flex"
                onClick={() =>
                  openPopupModal(item.kubeconfigDlink || item.kubeconfig)
                }
              >
                <i className="fa fa-download mr-2"></i> {"Download"}
              </button>
            </td>
          ),
          action: (item) => (
            <td>
                <button
                  id="btnDeleteCluster"
                  className="btn btn-sm btn-ghost-danger d-flex"
                  disabled={
                    userData.currentAccount.role == "user" &&
                    currentUser.uid !== item.owner
                  }
                  onClick={() => showDeleteAlert(item.id, item.name)}
                >
                  <i className="fa fa-trash mr-2"></i> {`Delete`}
                </button>
            </td>
          ),

          show_details: (item, index) => {
            return (
              <td className="py-2 expandIcon">
                <i
                  className={`fa ${
                    details.includes(index)
                      ? "fa-minus-square"
                      : "fa-plus-square"
                  } fa-lg`}
                  onClick={() => {
                    toggleDetails(index);
                  }}
                ></i>
              </td>
            );
          },
          details: (item, index) => {
            return (
              <CCollapse show={details.includes(index)}>
                {
                  <ClusterDetailsPanel
                    cluster={item}
                    currentDatacenter={currentDatacenter}
                    handleCopyToClipboard={handleCopyToClipboard}
                  />
                }
              </CCollapse>
            );
          },
        }}
      />
      {confirmDelete.show && confirmDelete.clusterId && (
        <CustomModal
          visible={showDeleteModal.open}
          setVisible={dismissDeleteAlert}
          header={`Confirm Delete`}
          content={
            <>
              <div className="d-flex align-items-baseline">
                <i className="fa fa-exclamation-triangle pr-2 text-warning"></i>
                <p>{`Are you sure you want to delete this cluster : ${confirmDelete.clusterName} ?`}</p>
              </div>
              {confirmDelete.errorMessage && (
                <h6
                  style={{ color: "red" }}
                >{`${confirmDelete.errorMessage} Please try again later.`}</h6>
              )}
              {showSpinner && (
                <div className="spinner">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Deleting...</span>
                  </div>
                </div>
              )}
            </>
          }
          successText={`Delete`}
          onSuccess={continueDeleteCluster}
        />
      )}
      {showPopupModal.open && (
        <KubeConfigModal
          config={showPopupModal.data}
          visible={showPopupModal.open}
          setVisible={setShowPopupModal}
        />
      )}
    </>
  );
};

export default ClustersView;
