import { CButton, CTooltip } from "@coreui/react";
import React, { useState } from "react";
import { FirebaseAuth } from "../../../../../../components/FirebaseAuth/firebase";
import CustomModal from "../../../../../../components/Modal";
import yaml from "js-yaml";
import Form from "../../../../../../components/Form/Form";
import Field from "../../../../../../components/Form/Field";

function AddonsTable({ features, addonIcon, cluster, calledFrom,delFeature }) {
  const [visible, setVisible] = useState({ open: false, data: null });
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    addon: null,
    errorMessage: null,
  });
  const [inputType, setInputType] = useState("password");
  const [spinner, setSpinner] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
  });

  const dismissDeleteAlert = (data) => {
    setConfirmDelete({
      show: false,
      addon: null,
      errorMessage: null,
    });

    setShowDeleteModal(data);
  };
  const showDeleteAlert = (addon) => {
    setConfirmDelete({
      show: true,
      addon: addon,
      errorMessage: null,
    });
    setShowDeleteModal({ open: true });
  };
  let optionalValues = {};
  let mandatoryValues = {};

  const extractCredentials = async (featureObj, feature) => {
    setSpinner(true);
    setVisible({
      open: true,
      data: {
        username: "",
        password: "",
        feature: feature,
        secretCommand: "",
        optionalValues: {},
      },
    });
    let currentFeature;
    let srcYaml;
    if (feature !== "cert_manager") {
      srcYaml = yaml.load(atob(featureObj.inputs));
    }
    await FirebaseAuth.firestore()
      .collection("marketplace")
      .where("id", "==", feature)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          currentFeature = doc.data();
        });
      })
      .catch((error) => {});

    let optionalValuesFields = Object.keys(currentFeature.optionalValues);
    let mandatoryValuesFields = Object.keys(currentFeature.values);

    const getValueFromyaml = (field) => {
      let fieldValueInYaml = srcYaml;
      let fieldpath = field.split(".");

      for (let i = 0; i < fieldpath.length; i++) {
        fieldValueInYaml = fieldValueInYaml[fieldpath[i]];
      }

      return fieldValueInYaml;
    };

    for (let i = 0; i < optionalValuesFields.length; i++) {
      optionalValues[optionalValuesFields[i]] = getValueFromyaml(
        optionalValuesFields[i]
      );
    }

    for (let i = 0; i < mandatoryValuesFields.length; i++) {
      mandatoryValues[mandatoryValuesFields[i]] = getValueFromyaml(
        mandatoryValuesFields[i]
      );
    }

    const [...username] =
      currentFeature?.credentialFields?.username?.split(".") || "";
    const [...password] =
      currentFeature?.credentialFields?.password?.split(".") || "";
    const secretCommand = currentFeature?.secretCommand || "";

    let resUsername = username.length ? srcYaml : "";
    let resPassword = password.length ? srcYaml : "";

    if (currentFeature?.credentialFields?.username) {
      for (let i = 0; i < username.length; i++) {
        resUsername = resUsername[username[i]] || "";
      }
    }

    if (currentFeature?.credentialFields?.password) {
      for (let i = 0; i < password.length; i++) {
        resPassword = resPassword[password[i]] || "";
      }
    }

    setVisible({
      open: true,
      data: {
        username: resUsername,
        password: resPassword,
        feature: feature,
        secretCommand: secretCommand,
        optionalValues: optionalValues,
        mandatoryValues: mandatoryValues,
      },
    });
    setSpinner(false);

    return [resPassword, resUsername];
  };

  const deleteFeature = async (feature) => {
    try {
      const docref = FirebaseAuth.firestore()
        .collection("clusters")
        .doc(cluster.id);

      await docref.set(
        {
          ...cluster,
          features: {
            [feature]: {
              ...features[feature],
              feature_status: "DELETE",
              exec_status: "DELETE_PENDING",
            },
          },
        },
        { merge: true }
      );
    } catch (err) {
      setConfirmDelete({ ...confirmDelete, errorMessage: err.message });
      console.log(err.message);
    }

    dismissDeleteAlert();
  };

  const copyToClipboard = () => {
    const el = document.getElementById("secretsCommand");
    el.select();
    document.execCommand("copy");
  };

  if (!features.length) {
    return null;
  }

  return (
    <>
      {visible.open && (
        <CustomModal
          visible={visible.open}
          setVisible={() => {
            setVisible({ open: false });
            setInputType("password");
          }}
          header={`Login credentials`}
          content={
            <center>
              <table>
                <tbody>
                  {spinner && <i className="fa fa-spinner fa-5x fa-spin" />}
                  {visible.data.username && (
                    <tr>
                      <td>User name</td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          style={{ width: "130%" }}
                          value={
                            [
                              "argocd",
                              "jenkins",
                              "permission-manager",
                            ].includes(visible.data.feature)
                              ? "admin"
                              : ["mysql", "redis", "postgresql"].includes(
                                  visible.data.feature
                                )
                              ? "root"
                              : visible.data.username
                          }
                          disabled
                        />
                      </td>
                      <td>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </td>
                    </tr>
                  )}
                  {visible.data.password && (
                    <tr>
                      <td>Password </td>
                      <td>
                        <input
                          className="form-control"
                          type={inputType}
                          style={{ width: "130%" }}
                          value={visible.data.password}
                          aria-label="Disabled input example"
                          disabled
                        />
                      </td>
                      <td>
                        <CButton>
                          {" "}
                          <i
                            className={
                              inputType === "password"
                                ? "fa fa-eye"
                                : "fa fa-eye-slash"
                            }
                            aria-hidden="true"
                            onClick={() =>
                              setInputType(
                                inputType === "password" ? "text" : "password"
                              )
                            }
                          />
                        </CButton>
                      </td>
                    </tr>
                  )}

                  {Object.keys(visible.data.mandatoryValues || {}).map(
                    (objkey, i) =>
                      typeof visible.data.mandatoryValues[objkey] != "object" &&
                      visible.data.mandatoryValues[objkey] &&
                      !(
                        objkey.toLocaleLowerCase().includes("password") ||
                        objkey.toLocaleLowerCase().includes("user")
                      ) && (
                        <tr>
                          <td>{objkey} </td>
                          <td>
                            <input
                              className="form-control"
                              style={{ width: "130%" }}
                              value={visible.data.mandatoryValues[objkey]}
                              disabled
                            />
                          </td>
                        </tr>
                      )
                  )}

                  {Object.keys(visible.data.optionalValues || {}).map(
                    (objkey, i) =>
                      typeof visible.data.optionalValues[objkey] != "object" &&
                      visible.data.optionalValues[objkey] && (
                        <tr>
                          <td>{objkey} </td>
                          <td>
                            <input
                              className="form-control"
                              style={{ width: "130%" }}
                              value={visible.data.optionalValues[objkey]}
                              disabled
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>

              {visible.data.secretCommand && (
                <>
                  <p className="text-info font-italic">
                    Run this command in the cluster to get the credentials.
                  </p>
                  <Form>
                    <Field>
                      <textarea
                        id="secretsCommand"
                        className="form-control"
                        placeholder="Code Block"
                        readOnly={true}
                        value={visible.data.secretCommand}
                      />
                    </Field>
                  </Form>
                </>
              )}

              {!visible.data.secretCommand &&
                !visible.data.password &&
                !visible.data.username &&
                !spinner && <>No credentials for this addon</>}
            </center>
          }
          successText={visible.data.secretCommand ? "copy to clipboard" : ""}
          onSuccess={copyToClipboard}
        />
      )}
      <table className="table addonstable">
        <thead>
          <tr>
            <th scope="col">Logo</th>
            <th scope="col">Name</th>
            <th scope="col">Service Url</th>
            <th scope="col">Status</th>
            {calledFrom !== "result" && <th scope="col">Delete</th>}
            <th scope="col">Credentials</th>
          </tr>
        </thead>
        <tbody>
          {features.map((feature, i) => {
            return (
              <tr key={i}>
                <td>
                  <img
                    src={addonIcon[Object.keys(feature)[0].toString()]}
                    style={{ height: 30, width: 30 }}
                    alt=""
                  ></img>
                </td>
                <td>{Object.keys(feature)[0]}</td>
                <td>
                  {Object.values(feature)[0].serviceUrl ? (
                    <span>
                      <a
                        href={"https://" + Object.values(feature)[0].serviceUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa fa-external-link-square"
                          aria-hidden="true"
                        ></i>
                        URL
                      </a>
                    </span>
                  ) : (
                    "NA"
                  )}
                </td>
                <td>
                  {(() => {
                    switch (Object.values(feature)[0].exec_status) {
                      case "DONE":
                        return (
                          <CTooltip content="Installed" placement="left">
                            <i
                              className="fa fa-check-circle"
                              style={{ color: "green" }}
                            />
                          </CTooltip>
                        );
                      case "DELETE_DONE":
                        return (
                          <CTooltip content="Installed" placement="left">
                            <i
                              className="fa fa-check-circle"
                              style={{ color: "green" }}
                            />
                          </CTooltip>
                        );
                      case "INPROGRESS":
                        return (
                          <CTooltip content="In progress" placement="left">
                            <i
                              className="fa fa-spinner fa-1x fa-spin"
                              style={{ color: "green" }}
                            />
                          </CTooltip>
                        );
                      case "DELETE_INPROGRESS":
                        return (
                          <CTooltip
                            content="Deletion inprogress"
                            placement="left"
                          >
                            <i
                              className="fa fa-spinner fa-1x fa-spin"
                              style={{ color: "green" }}
                            />
                          </CTooltip>
                        );

                      case "PENDING":
                        return (
                          <CTooltip content="Pending" placement="left">
                            <i
                              className="fa fa-exclamation-triangle"
                              style={{ color: "red" }}
                            />
                          </CTooltip>
                        );
                      case "DELETE_PENDING":
                        return (
                          <CTooltip content="Deletion pending" placement="left">
                            <i
                              className="fa fa-exclamation-triangle"
                              style={{ color: "red" }}
                            />
                          </CTooltip>
                        );
                      default:
                        return (
                          <CTooltip content="Failed" placement="left">
                            <i
                              className="fa fa-times-circle"
                              style={{ color: "red" }}
                            />
                          </CTooltip>
                        );
                    }
                  })()}
                </td>
                {calledFrom !== "result" && (
                  <td>
                    <CButton
                      onClick={() => delFeature && showDeleteAlert(Object.keys(feature)[0])}
                    >
                        <CTooltip content={delFeature ? "Delete" : "Please wait.."} placement="left">
                            <i
                              className="fa fa-trash mr-2"
                              style={{ color: "red" }}
                            />
                          </CTooltip>
                    </CButton>
                    {confirmDelete.show && (
                      <CustomModal
                        visible={showDeleteModal.open}
                        setVisible={dismissDeleteAlert}
                        header={`Confirm Delete`}
                        content={
                          <>
                            <div className="d-flex align-items-baseline">
                              <i className="fa fa-exclamation-triangle pr-2 text-warning"></i>
                              <p>
                                Are you sure you want to delete this add-on :{" "}
                                <b>{confirmDelete.addon}</b> ?
                              </p>
                            </div>
                            {confirmDelete.errorMessage && (
                              <h6
                                style={{ color: "red" }}
                              >{`${confirmDelete.errorMessage} Please try again later.`}</h6>
                            )}
                          </>
                        }
                        successText={`Delete`}
                        onSuccess={() => deleteFeature(confirmDelete.addon)}
                      />
                    )}
                  </td>
                )}
                <td>
                  <CButton
                    onClick={() =>
                      extractCredentials(
                        Object.values(feature)[0],
                        Object.keys(feature)[0]
                      )
                    }
                  >
                    <i className="fa fa-key" aria-hidden="true"></i>
                  </CButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default AddonsTable;
