import {
  CButton,
  CCardBody,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FirebaseAuth } from "../../../../../../components/FirebaseAuth/firebase";
import Features from "../Features";
import AddOns from "./AddOns";
import Overview from "./Overview";

const ClusterDetailsPanel = ({
  cluster,
  handleCopyToClipboard,
  currentDatacenter,
}) => {
  const existedFeatures = Object.keys(cluster.features).filter(
    (item) => cluster.features[item].feature_status !== "DELETE_DONE"
  );
  const [activeKey, setActiveKey] = useState(2);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedFeatureInputs, setSelectedFeatureInputs] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const toggleFeatureSelection = (ftrId, inputs, formOptions) => {
    if (inputs) {
      setSelectedFeatureInputs((prevState) => [
        ...prevState.filter((x) => Object.keys(x)[0] !== ftrId),
        {
          [ftrId]: inputs,
        },
      ]);
      setFormValues((prevState) => [
        ...prevState.filter((x) => Object.keys(x)[0] !== ftrId),
        {
          [ftrId]: formOptions,
        },
      ]);
    }
    let curFtr = selectedFeatures.find((x) => x === ftrId);
    if (curFtr) {
      //remove if already exists
      setSelectedFeatures((prevState) => [
        ...prevState.filter((x) => x !== ftrId),
      ]);
    } else {
      //add if doesnt exist
      setSelectedFeatures((prevState) => [...prevState, ftrId]);
    }
    // select when save
    if (formOptions) {
      //add if doesnt exist
      setSelectedFeatures((prevState) => [...prevState, ftrId]);
    }
  };

  const addFeaturesToExistingCluster = async () => {
    const docref = await FirebaseAuth.firestore()
      .collection("clusters")
      .doc(cluster.id);

    await docref.set(
      {
        ...cluster,
        features: {
          ...cluster.features,
          ...featuresList.reduce((a, b) => Object.assign(a, b), {}),
        },
      },
      { merge: true }
    );
    setActiveKey(2);
    setSelectedFeatures([]);
  };
  const featuresList = [
    ...selectedFeatures.map((ftr) => {
      return {
        [ftr]: {
          requested: true,
          inputs:
            selectedFeatureInputs.filter(
              (x) => Object.keys(x)[0] === ftr
            )?.[0]?.[ftr] || {},
          exec_status: "PENDING",
          feature_status: "NEW",
        },
      };
    }),
  ];

  return (
    <CCardBody style={{ paddingTop: "10px" }}>
      <CNav variant="tabs" role="tablist">
        <CNavItem>
          <CNavLink active={activeKey === 1} onClick={() => setActiveKey(1)}>
            Overview
          </CNavLink>
        </CNavItem>

        <CNavItem>
          <CNavLink active={activeKey === 2} onClick={() => setActiveKey(2)}>
            Add Ons
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={activeKey === 3} onClick={() => setActiveKey(3)}>
            MarketPlace
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={activeKey === 4} onClick={() => setActiveKey(4)}>
            MyMarketPlace
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent>
        <CTabPane
          role="tabpanel"
          active={activeKey === 1}
          aria-labelledby="overview-tab"
        >
          <Overview
            cluster={cluster}
            handleCopyToClipboard={handleCopyToClipboard}
            datacenter={currentDatacenter}
          />
        </CTabPane>

        <CTabPane
          role="tabpanel"
          active={activeKey === 2}
          aria-labelledby="addons-tab"
        >
          <AddOns cluster={cluster} calledFrom="clusteDetailsPanel" />
        </CTabPane>
        <CTabPane
          role="tabpanel"
          active={activeKey === 3}
          aria-labelledby="addons-tab"
        >
          <Features
            selectedFeatures={selectedFeatures}
            selectedFeatureInputs={selectedFeatureInputs}
            formValues={formValues}
            toggleFeatureSelection={toggleFeatureSelection}
            existedFeatures={existedFeatures}
          />

          {selectedFeatures.length > 0 && (
            <CButton color="primary" onClick={addFeaturesToExistingCluster}>
              submit
            </CButton>
          )}
        </CTabPane>
        <CTabPane
          role="tabpanel"
          active={activeKey === 4}
          aria-labelledby="mymarketplace-tab"
        >
          <div className="text-right mb-3">
               
               <Link to="My-Addon" className="btn btn-primary">
                 <i className="fa fa-plus"></i> your Addon
               </Link>
               <Features
            selectedFeatures={selectedFeatures}
            selectedFeatureInputs={selectedFeatureInputs}
            formValues={formValues}
            toggleFeatureSelection={toggleFeatureSelection}
            existedFeatures={existedFeatures}
            calledFrom = {"myMarketPlace"}
          />
             </div>
             {selectedFeatures.length > 0 && (
            <CButton color="primary" onClick={addFeaturesToExistingCluster}>
              submit
            </CButton>
          )}
        </CTabPane>
      </CTabContent>
    </CCardBody>
  );
};

export default ClusterDetailsPanel;
