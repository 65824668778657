/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { AuthContext } from "../../../../../components/FirebaseAuth";
import { RESOURCES_FOR_PLAN } from "../../../../../constants/clusters";

const SpecsButtonGroup = ({
  clusterResource,
  clusterSizeIndex,
  setClusterSizeIndex,
  currentAccountUsed,
  ClusterName,
}) => {
  const { userData, authUser } = useContext(AuthContext);
  const ResourceLeft = RESOURCES_FOR_PLAN[userData.currentAccount.plan]["CPU"] - currentAccountUsed.sizes.CPU;

  return (
    <div className="clustersGrid">
      {
        clusterResource
          .map((cluster, key) => (
            <div
              key={key}
              onClick={() =>
                ClusterName.value&&cluster.CPU <= ResourceLeft && setClusterSizeIndex(key)
              }
              className={`card ${clusterSizeIndex === key 
                && "active"} ${(cluster.CPU > ResourceLeft|| !ClusterName.value)&&"disable"}`}
            >
              <div className="card-header" style={{color:"black"}}>{`${cluster.name}`}</div>
              <div className="card-body d-flex flex-column text-center">
                <i className="fa fa-cloud"></i>
                <span>{`${cluster.CPU} CPU`}</span>
                <span>{`${cluster.RAM} GB RAM`}</span>
                <span>{`${cluster.SSD} GB SSD`}</span>
              </div>
            </div>
          ))}
    </div>
  );
};

export default SpecsButtonGroup;
