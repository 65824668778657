import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CButton, CForm, CLabel, CSelect } from "@coreui/react";
import Stepper from "bs-stepper";
import yaml from "js-yaml";

import { BreadcrumbContext } from "../../../../../components/Breadcrumb";
import { AuthContext } from "../../../../../components/FirebaseAuth";
import {
  CloudFunctions,
  FirebaseAuth,
  LogAnalyticsEvent,
} from "../../../../../components/FirebaseAuth/firebase";
import { Field, Input } from "../../../../../components/Form";
import Step from "../../../../../components/Stepper/Step";
import StepContent from "../../../../../components/Stepper/StepContent";
import { ADDON_CATEGORIES } from "../../../../../constants/clusters";
import Loading from "./Loading";

const NewCustemAddon = () => {
  const [HelmrepoYAML, setHelmrepoYAML] = useState({});
  const [Versions, setVersions] = useState([]);
  const [RepoURL, setRepoURL] = useState("");
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedVersion, setSelectedVersion] = useState();
  const [tarfile, setTarFile] = useState();
  const title = "Add your addon";
  const { userData, authUser } = useContext(AuthContext);
  const [newAddonName, setNewAddonName] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [addonCategory, setAddonCategory] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [labelKey, setLabelKey] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [labelvalue, setLabelvalue] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [addonName, setAddonName] = useState("");
  const [formValues, setFormValues] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  /* eslint-disable */
  const [successMessage, setSuccessMessage] = useState(null);

  const [error, setError] = useState({
    status: false,
    messages: [],
  });

  const [inSubmit, setInSubmit] = useState(false);
  const [disableDropdown, setDisableDropDown] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const getHelmrepoYaml = async (repoUrl) => {
    setDisableDropDown(true);
    const getYamlFromRepoUrl =
      CloudFunctions.httpsCallable("getYamlFromRepoUrl");
    const repoYaml = await getYamlFromRepoUrl({ repoUrl: repoUrl });
    setHelmrepoYAML(repoYaml.data.data);
    setDisableDropDown(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(null);

    const addonName = document.getElementById("newAddonName").value;
    const labelKey = document.getElementById("key").value;
    const labelvalue = document.getElementById("value").value;
    const category = document.getElementById("select Category").value;
    const chart = document.getElementById("select chart").value;
    const version = document.getElementById("select version").value;
    const file_url = HelmrepoYAML.entries[chart].filter((obj) =>
      Object.values(obj).includes(version)
    )[0].urls[0];
    const full_url = file_url.startsWith("http") ? file_url : `${RepoURL?.value}/${file_url}`;

    if (!addonName) return null;

    setInSubmit(true);
    const getYamlValues = CloudFunctions.httpsCallable("getYamlValues");

    const res = await getYamlValues({ file_url: full_url, folder: chart });

    const marketplaceRef = FirebaseAuth.firestore().collection("marketplace");
    await marketplaceRef.add({
      category: category,
      customerAddon: true,
      defaultEnable: false,
      showInMarketPlace: true,
      owned_by: userData.currentAccount.id,
      defaultYaml: res.data.data,
      description: HelmrepoYAML.entries[chart].filter((obj) =>
        Object.values(obj).includes(chart)
      )[0].description,
      displayName: addonName,
      helmRepoAdd: RepoURL.value,
      icon:
        HelmrepoYAML.entries[chart].filter((obj) =>
          Object.values(obj).includes(chart)
        )[0]?.icon || "",
      id: chart,
      version: version,
      chart: chart,
      optionalValues: {},
      values: {},
      labelCheck: { key: labelKey, value: labelvalue },
    });
    setInSubmit(false);

    setError({ status: true, messages: [] });
    moveNextStep();
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData?.currentAccount?.id + "/",
        text: userData?.currentAccount?.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [userData, setBreadcrumb, title]);

  useEffect(() => {
    moveNextStep();
  }, []);

  const moveNextStep = () => {
    var stepper = new Stepper(document.querySelector("#stepper1"));

    stepper.to(currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const movePreviousStep = () => {
    var stepper = new Stepper(document.querySelector("#stepper1"));

    stepper.to(currentStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const handleCloseForm = () => {
    setRedirect("/account/" + userData?.currentAccount?.id + "/");
  };

  return (
    <>
      {redirect === null && (
        <div className="container-fluid">
          <div className="animated fadeIn">
            <div className="card">
              <div className="card-header">{title}</div>
              <div className="card-body">
                <StepperComponent
                  {...{
                    handleFormSubmit,
                    newAddonName,
                    labelKey,
                    labelvalue,
                    setLabelKey,
                    setLabelvalue,
                    RepoURL,
                    setRepoURL,
                    HelmrepoYAML,
                    setHelmrepoYAML,
                    selectedChart,
                    setSelectedChart,
                    selectedVersion,
                    setSelectedVersion,
                    tarfile,
                    setTarFile,
                    Versions,
                    setVersions,
                    addonName,
                    addonCategory,
                    setAddonName,
                    setNewAddonName,
                    setAddonCategory,
                    inSubmit,
                    disableDropdown,
                    currentStep,
                    moveNextStep,
                    movePreviousStep,
                    handleCloseForm,
                    formValues,
                    error,
                    getHelmrepoYaml,
                  }}
                />
              </div>
              <div className="card-footer text-success text-center">
                Datacenter: {userData.currentAccount.datacenter}
              </div>
            </div>
          </div>
        </div>
      )}
      {redirect !== null && <Redirect to={redirect}></Redirect>}
    </>
  );
};

const StepperComponent = ({
  handleFormSubmit,
  ClusterName,
  newAddonName,
  setNewAddonName,
  labelKey,
  labelvalue,
  setLabelKey,
  setLabelvalue,
  addonName,
  addonCategory,
  setAddonCategory,
  setAddonName,
  ClusterDescription,
  inSubmit,
  disableDropdown,
  currentStep,
  moveNextStep,
  movePreviousStep,
  handleCloseForm,
  RepoURL,
  setRepoURL,
  HelmrepoYAML,
  setHelmrepoYAML,
  selectedChart,
  setSelectedChart,
  selectedVersion,
  setSelectedVersion,
  Versions,
  setVersions,
  getHelmrepoYaml,
}) => (
  <div id="stepper1" className="bs-stepper">
    <div className="bs-stepper-header stepperHeader">
      <Step
        dataTarget="#AddonName"
        stepperNumber="1"
        stepperText="Addon Name&category"
      />
      <div className="line stepperLine"></div>
      <Step
        dataTarget="#MyAddonSpec"
        stepperNumber="2"
        stepperText="Addon Specs"
      />
      <div className="line"></div>
      <Step
        dataTarget="#resultDiv"
        stepperNumber="3"
        stepperText="Custom Addon Result"
      />
    </div>
    <div className="bs-stepper-content">
      <StepContent
        divId="AddonName"
        content={
          <CForm>
            <Field>
              <CLabel htmlFor="name">*Name :</CLabel>

              <Input
                type="text"
                id="newAddonName"
                name="new-addon-name"
                placeholder="Name of the Addon"
                maxLen={100}
                required={true}
                changeHandler={setNewAddonName}
              />
            </Field>

            <Field>
              <CLabel htmlFor="category">Category :</CLabel>

              <Input
                list="categories"
                id="select Category"
                placeholder="select/Enter category"
                changeHandler={setAddonCategory}
                required={true}
                // defaultValue={ADDON_CATEGORIES[7].value}
              />
              <datalist id="categories">
                {ADDON_CATEGORIES.map((category, i) => {
                  return (
                    <option key={i} value={category.value}>
                      {category.value}
                    </option>
                  );
                })}
              </datalist>
            </Field>
          </CForm>
        }
        ClusterName={ClusterName}
        addonName={addonName}
        addonCategory={addonCategory}
        setAddonCategory={setAddonCategory}
        setAddonName={setAddonName}
        nextFunction={moveNextStep}
        previousFunction={movePreviousStep}
        showNext={true}
        calledFrom={"NewCustomAddon"}
        newAddonName={newAddonName}
        RepoURL={RepoURL}
        setRepoURL={setRepoURL}
        HelmrepoYAML={HelmrepoYAML}
        setHelmrepoYAML={setHelmrepoYAML}
        Versions={Versions}
        setVersions={setVersions}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        getHelmrepoYaml={getHelmrepoYaml}
      />
      <StepContent
        divId="MyAddonSpec"
        content={
          <CForm>
            <Field>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter helm Repo URL"
                  aria-describedby="button-addon2"
                  onChange={(e) =>
                    setRepoURL({ ...RepoURL, value: e.target.value })
                  }
                />

                <button
                  class="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  onClick={() => getHelmrepoYaml(RepoURL?.value)}
                  disabled={!RepoURL.value}
                >
                  Get charts
                </button>
              </div>
            </Field>
            {disableDropdown && (
              <Field>
                <Loading />
              </Field>
            )}

            <Field>
              <CLabel htmlFor="Charts">Charts :</CLabel>
              <CSelect
                id="select chart"
                defaultValue={"--select Chart--"}
                onChange={(e) => setSelectedChart(e.target.value)}
                disabled={disableDropdown}
              >
                <option value={""} disabled selected>
                  --select chart--
                </option>
                {Object.keys(HelmrepoYAML.entries || {}).map((key, i) => (
                  <option key={i} value={key}>
                    {key}
                  </option>
                ))}
              </CSelect>
            </Field>

            <Field>
              <CLabel htmlFor="versions">versions :</CLabel>
              <CSelect
                id="select version"
                disabled={disableDropdown}
                onChange={(e) => setSelectedVersion(e.target.value)}
              >
                <option value={""} disabled selected>
                  --selectVersion--
                </option>
                {Object.values(Object.values(HelmrepoYAML.entries || {}))
                  .filter((obj) => obj[0]["name"] == selectedChart)
                  .map((obj, i) =>
                    obj.map((ele, i) => (
                      <option key={i} value={ele["version"]}>
                        {ele["version"]}
                      </option>
                    ))
                  )}
              </CSelect>
            </Field>
            <Field>
              <CLabel htmlFor="Key">*key :</CLabel>

              <Input
                type="text"
                id="key"
                name="key"
                placeholder="Label Key"
                maxLen={100}
                required={true}
                changeHandler={setLabelKey}
              />
            </Field>
            <Field>
              <CLabel htmlFor="value">*value :</CLabel>

              <Input
                type="text"
                id="value"
                name="value"
                placeholder="Label value"
                maxLen={100}
                required={true}
                changeHandler={setLabelvalue}
              />
            </Field>
          </CForm>
        }
        previousFunction={movePreviousStep}
        showForm={true}
        handleFormSubmit={handleFormSubmit}
        ClusterName={ClusterName}
        ClusterDescription={ClusterDescription}
        inSubmit={inSubmit}
        currentStep={currentStep}
        showPrevious={true}
        calledFrom={"NewCustomAddon"}
        newAddonName={newAddonName}
        RepoURL={RepoURL}
        setRepoURL={setRepoURL}
        HelmrepoYAML={HelmrepoYAML}
        setHelmrepoYAML={setHelmrepoYAML}
        Versions={Versions}
        setVersions={setVersions}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
        getHelmrepoYaml={getHelmrepoYaml}
      />
      <StepContent
        divId="resultDiv"
        content={
          <div className="success-message">
            Your addon was added successfully
          </div>
        }
        doneFunction={handleCloseForm}
        previousFunction={movePreviousStep}
        isLast={true}
        calledFrom={"NewCustomAddon"}
        newAddonName={newAddonName}
        RepoURL={RepoURL}
        setRepoURL={setRepoURL}
        HelmrepoYAML={HelmrepoYAML}
        setHelmrepoYAML={setHelmrepoYAML}
        Versions={Versions}
        setVersions={setVersions}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
      />
    </div>
  </div>
);

export default NewCustemAddon;
