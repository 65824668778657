import React from "react";
import UserMenu from "../../../../components/menus/UserMenu";
import AppLogo from "../../../../assets/logo.png";

const UserNotAccepted = () => {
  return (
    <div className="c-app">
      <div className="c-wrapper">
        <header className="c-header c-header-light c-header-fixed">
          <div className="mfe-auto">
            <button
              className="c-header-toggler c-class-toggler mfs-3 d-md-down-none"
              data-target="#sidebar"
              data-class="c-sidebar-lg-show"
            >
              <i className="mt-3 fa fa-bars" />
            </button>
          </div>
          <ul className="c-header-nav">
            <UserMenu />
          </ul>
        </header>
        <div className="c-body">
          <main className="c-main">
            <div className="userNotAcceptedPage d-flex flex-column justify-content-center align-items-center">
              <img src={AppLogo} alt="Logo" width={'50%'} height={'auto'} style={{ padding: '2em' }} />
              <h2 style={{ maxWidth: '80%', fontSize: '3vmax' }}>Thanks for overwhelming support.</h2>
              <h3 style={{ maxWidth: '80%', fontSize: '2vmax' }}>
                You are in queue, we will send you email as soon as you are
                accepted
              </h3>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default UserNotAccepted;
