import React, { useState, useEffect } from "react";
import { FirebaseAuth } from "../../../../../../components/FirebaseAuth/firebase";
import AddonsTable from "./AddonsTable";
import warning from "../../../../../../assets/images/warning.png";
const AddOns = ({ cluster, calledFrom }) => {
  let [addonIcon, setAddonIcon] = useState({});
  const queryMarketplace = async () => {
    const marketplaceRef = await FirebaseAuth.firestore()
      .collection("marketplace")
      .get();
    let icons = {};
    marketplaceRef.forEach((adn) => {
      let addon = adn.data();
      icons[addon.id] = addon?.icon;
    });
    setAddonIcon(icons);
  };
  useEffect(() => {
    queryMarketplace();
  }, []);
  let requestedFeatures = () => {
    const sortedKeys = Object.keys(cluster.features).sort();
    return sortedKeys
      .filter(
        (k) =>
          cluster.features[k].requested &&
          cluster.features[k].feature_status !== "DELETE_DONE"
      )
      .map((key) => ({ [key]: cluster.features[key] }));
  };
  const leftFeats = requestedFeatures().filter((feat, i) => i % 2 === 0);
  const rightFeats = requestedFeatures().filter((feat, i) => i % 2 === 1);
  if (requestedFeatures().length === 0) {
    return (
      <center>
        <img src={warning} width="100rem" alt = ""/>
        <h4>No apps installed!</h4>
      </center>
    );
  }
  const delFeature = requestedFeatures().every(obj => Object.values(obj)[0].exec_status ==="DONE" || Object.values(obj)[0].exec_status ==="ERROR");

  return calledFrom !== "result" ? (
    <div className="addonsTableWrapper">
      <AddonsTable {...{ features: leftFeats, addonIcon, cluster ,delFeature}} />
      <AddonsTable {...{ features: rightFeats, addonIcon, cluster ,delFeature}} />
    </div>
  ) : (
    <div style={{ border: "3px solid var(--light)" }}>
      <AddonsTable {...{ features: requestedFeatures(), addonIcon, cluster ,calledFrom}} />
    </div>
  );
};
export default AddOns;
