import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APIKey from "../accounts/APIKey";
import AccountOverview from "./AccountOverview";

const AccountCard = ({ account, accountPlans, clusters, index, clusterResource }) => {

  const [dcName, setDcName] = useState("");
  const [currentAccountUsed, setCurrentAccountUsed] = useState({
    accountId: account.id,
    sizes: {
      RAM: 0,
      CPU: 0,
      STORAGE: 0,
    },
  });

  useEffect(() => {
    account.datacenter?.then((dc) => {
      setDcName(dc.data().name);
    });

  }, [account.id, account.datacenter]);
  /* eslint-disable */

  useEffect(() => {
    const currentCluster = clusters.filter((x) => x.accountId === account.id);
    if (currentCluster) {
      let RAM = 0;
      let CPU = 0;
      let SSD = 0;
      currentCluster.map((clr) => {// eslint-disable-line
        const sizeInfo = clusterResource.find((x) => x.id === clr.size);
        if (sizeInfo) { 
          RAM += sizeInfo.RAM;
          CPU += sizeInfo.CPU;
          SSD += sizeInfo.SSD;
        }
      });
      setCurrentAccountUsed({
        ...currentAccountUsed,
        sizes: {
          RAM: RAM,
          CPU: CPU,
          STORAGE: SSD
        }
      });
    } 
  }, [account, clusters, clusterResource]); 
  /* eslint-enable */

  return (
    <div className="card">
      <div className="card-header font-weight-bold d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="w-50">{account.name}</h5>
          <h6 style={{ color: "var(--success)" }} className="my-1 w-50">
            {dcName}
          </h6>
        </div>
        <div className="d-flex w-25 justify-content-end">
          {account.plan && (
            <h6
              className="p-1 mr-4"
              style={{
                color: "var(--clr-menu-hover-font)",
                backgroundColor: "var(--clr-dark-bg-grey)",
                borderRadius: "0.7em",
              }}
            >
              {account.plan}
            </h6>
          )}
          <Link
            to={
              account.plan
                ? "/account/" + account.id + "/billing/delete"
                : "/account/" + account.id + "/billing/delete-account"
            }
          >
            <i
              id={`btnDeleteCurrentAccount_${index}`}
              className="fa fa-trash fa-lg"
              style={{ color: "var(--danger)", cursor: "pointer" }}
              data-toggle={"tooltip"}
              data-placement="top"
              title={"Delete this account"}
            />
          </Link>
        </div>
      </div>
      <div className="card-body">
      <div className="d-grid d-md-flex justify-content-md-center">
        {account.subscriptionStatus ? (
            <Link  className="btn btn-outline-primary btn-lg" to={"/account/" + account.id + "/"} account={account}>
              <i className="fa fa-cogs" style={{ marginRight: "8px"}}> </i> Go To Cluster s
            </Link>
        ) : (
          <Link  className="btn btn-outline-info btn-lg"
            to={{
              pathname: "/account/" + account.id + "/billing/plan",
            }}
          >
            Activate the account
          </Link>
        )}
        </div>
        {account.subscriptionStatus &&
          account.plan &&
          accountPlans.length > 0 && (
            <AccountOverview
              account={account}
              accountPlans={accountPlans}
              clusters={clusters}
              clusterResource={clusterResource}
              currentAccountUsed={currentAccountUsed}
              setCurrentAccountUsed={setCurrentAccountUsed}
            />
          )}
      </div>
      <div className="card-footer apiKey">
        <APIKey account={account} />{" "}
      </div>
    </div>
  );
};

export default AccountCard;
