import { CCallout, CCol, CRow } from "@coreui/react";
import React from "react";

const Overview = ({ cluster, handleCopyToClipboard, datacenter }) => {
  const CLUSTER_GET_MOREINFO = [
    { Description: `${cluster.description}` },
    { "K3s Version": `${cluster.version || `v1.19.5-k3s2 (Default)`}` },
    {
      "DNS Name": datacenter
        ? `${cluster.id}.${datacenter.appsSubDomain}`
        : "-",
      copy: true,
    },
    {
      "App Domain": datacenter
        ? `<yourapp>-${cluster.id}.${datacenter.appsSubDomain}`
        : "-",
      copy: true,
    },
    { "Size": cluster.size},
    {
      "API Endpoint": datacenter
        ? `https://vc-${cluster.id}.${datacenter.subDomain}`
        : "-",
      copy: true,
    },
  ];

  return (
    <CRow>
      {CLUSTER_GET_MOREINFO.map((info, i) => {
        return (
          <CCol
            key={i}
            col="12"
            sm="6"
            className="d-flex align-clusters-center"
          >
            <CCallout
              color={"warning"}
              className={"bg-white my-1 pr-2"}
              style={{ maxWidth: "90%" }}
            >
              <small className="text-muted">
                {Object.keys(info)[0].toLocaleLowerCase()}
              </small>
              <br />
              <h6 id={`copy_${i}_${cluster.id}`}>
                {Object.values(info)[0].toLocaleLowerCase()}
              </h6>
            </CCallout>
            {info.copy && (
              <i
                className="fa fa-copy text-primary"
                style={{ cursor: "pointer" }}
                data-toggle={"tooltip"}
                data-placement="top"
                title={"Copy to clipboard"}
                onClick={() => handleCopyToClipboard(`copy_${i}_${cluster.id}`)}
              ></i>
            )}
          </CCol>
        );
      })}
    </CRow>
  );
};

export default Overview;
