import React from "react";

const Step = ({ dataTarget, stepperNumber, stepperText }) => {
  return (
    <div className="step" data-target={dataTarget}>
      <button type="button" className="btn step-trigger">
        <span className="bs-stepper-circle">{stepperNumber}</span>
        <span className="bs-stepper-label">{stepperText}</span>
      </button>
    </div>
  );
};

export default Step;
