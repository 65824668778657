import React, { useState, useContext, useEffect, useRef } from "react";
import { browserName } from "react-device-detect";

import { BreadcrumbContext } from "../../../components/Breadcrumb";
import {
  FirebaseAuth,
  LogAnalyticsEvent,
} from "../../../components/FirebaseAuth/firebase";
import { Link, Redirect } from "react-router-dom";
import Loader from "../../../components/Loader";
import AccountCard from "./AccountCard";

const Home = () => {
  const title = "My Accounts";

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [loading, setLoading] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const [clusterResource, getClusterResource] = useState([]);

  const mountedRef = useRef(true);

  const getAccounts = () => {
    setLoading(true);
    let records = [];
    const accountsRef = FirebaseAuth.firestore().collection("accounts");
    let query = accountsRef.where(
      "access",
      "array-contains",
      FirebaseAuth.auth().currentUser.uid
    );
    query
      .get()
      .then((accountSnapshots) => {
        if (!mountedRef.current) return null;
        setLoadingPlan(true);
        accountSnapshots.forEach(async (account) => {
          records.push({
            id: account.id,
            name: account.data().name,
            subscriptionStatus: account.data().subscriptionStatus,
            apiKey: account.data().apiKey,
            plan: account.data().plan,
            datacenterId: account.data().datacenter?.id,
            datacenter: account.data().datacenter?.get(),
          });
        });
        setAccounts(records);
        setLoading(false);
        setLoadingPlan(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoadingPlan(false);
      });
  };

  const getClusterResources = () => {
    // const clusterResourceRef = 
    FirebaseAuth.firestore()
      .collection("clusterResources")
      .get()
      .then((clrSnapshots) => {
        if (clrSnapshots) {
          clrSnapshots.forEach((option) => {
            if (option.data().id) {
              getClusterResource((prevState) => [...prevState, option.data()]);
            }
          });
        }
      });
  };

  // Get clusters for each account and add to state with mapping
  const getClusters = () => {
    const clustersRef = FirebaseAuth.firestore().collection("clusters");
    //let query = clustersRef.where("status", "==", "READY");

    let items = [];
    clustersRef.get().then((clustersSnapshots) => {
      clustersSnapshots.forEach((cluster) => {
        items.push({
          clusterId: cluster.id,
          // ownerId: cluster.data().owner,
          accountId: cluster.data().accountId,
          size: cluster.data().size,
        });
      });
      setClusters(items);
    });
  };

  // Get account Plans whenever accounts are changed
  useEffect(() => {
    getClusterResources();
    if (accounts.length > 0) {
      Promise.all(accounts.map((a) => a.datacenter))
        .then((responses) => responses.map((x) => x?.data()).filter((x) => x))
        .then((datacenters) => {
          if (datacenters) {
            let currentplans = [];
            for (var i = 0; i < accounts.length; i++) {
              const acc = accounts[i];
              // console.log(datacenters, acc);
              if (acc.plan) {
                // account is activated
                const currentPlanInfo = datacenters.filter(
                  (x) => x.id === acc.datacenterId
                )?.[0]?.plans[acc.plan];

                currentplans.push({
                  accountId: acc.id,
                  plan: acc.plan,
                  planTotals: {
                    RAM: currentPlanInfo.ramLimit,
                    CPU: currentPlanInfo.cpuLimit,
                    STORAGE: currentPlanInfo.storageLimit,
                  },
                  planDetails: currentPlanInfo?.features,
                });
              }
            }
            setAccountPlans(currentplans);
          }
        });
    }
  }, [accounts]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    getAccounts();
    getClusters();

    LogAnalyticsEvent("load_home", {
      browserName: browserName,
      user_name: FirebaseAuth.auth().currentUser.displayName || "",
      user_email: FirebaseAuth.auth().currentUser.email || "",
    });

    return () => {
      mountedRef.current = false;
    };
  }, [setBreadcrumb]);

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {accounts.length > 0 ? (
            <>
              <div className="text-right mb-3">
                <Link to="/new-account" className="btn btn-primary">
                  <i className="fa fa-plus"></i> Add Account
                </Link>
              </div>

              {accounts.map((account, i) => (
                <AccountCard
                  key={account.id}
                  index={i}
                  account={account}
                  accountPlans={accountPlans}
                  clusters={clusters}
                  clusterResource={clusterResource}
                />
              ))}
            </>
          ) : (
            <>
              {loading || loadingPlan ? (
                <Loader text="loading accounts..."></Loader>
              ) : (
                <Redirect to="/new-account"></Redirect>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
