import { CPopover } from "@coreui/react";
import React, { useState } from "react";
import {
  CloudFunctions,
} from "../../../../components/FirebaseAuth/firebase";
import CustomModal from "../../../../components/Modal";
import CLI from "./CLI";

const APIKey = ({ account }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [apiKey, setApiKey] = useState(account.apiKey);
  const [status, setStatus] = useState({
    show: false,
    value: "inprogress",
  });
  const [showModal, setShowModal] = useState({ open: false, data: null });

  const handleCopyToClipboard = (e) => {
    e.preventDefault();
    var temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = e.target.value;
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  };

  const openModal = () => {
    setShowModal({ open: true });
  };

  const dismissModal = (data) => {
    setShowModal(data);
  };

  const generateNewKey = () => {
    setStatus({
      value: "inprogress",
      show: true,
    });
    const generateNewKey = CloudFunctions.httpsCallable("generateApiKey");
    generateNewKey({ accountId: account.id })
      .then(async (response) => {
        if (response.data?.status) {
          //successfully created
          setApiKey(response.data?.apiKey);
          setStatus({
            show: true,
            value: "success",
          });
        } else {
          setStatus({
            show: true,
            value: "error",
          });
        }
      })
      .catch((err) => {
        setStatus({
          show: true,
          value: "error",
        });
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setStatus({
            show: false,
            value: "success",
          });
        }, 5000);
      });
  };

  return (
    <>
      <div
        className={`btn btn-light${status.show && " disabled"}`}
        onClick={generateNewKey}
      >
        Generate a new API key
        {status.show && (
          <>
            {status.value === "inprogress" && (
              <i className="fa fa-spinner inprogress" />
            )}
            {status.value === "success" && (
              <i className="fa fa-check-circle success" />
            )}
            {status.value === "error" && (
              <i className="fa fa-exclamation-circle error" />
            )}
          </>
        )}
      </div>
      {apiKey && (
        <div className="input-group apiKeyText">
          <CPopover
            placement="bottom"
            content={
              <i
                className="fa fa-check fa-lg"
                style={{ color: "var(--success)" }}
              >{` Copied`}</i>
            }
            trigger="click"
          >
            <input
              className="form-control"
              type={showPassword ? "text" : "password"}
              // disabled
              defaultValue={apiKey}
              data-toggle={"tooltip"}
              data-placement="top"
              title={"Click to copy"}
              onClick={handleCopyToClipboard}
              style={{ cursor: "pointer" }}
            />
          </CPopover>
          <i
            className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
            aria-hidden="true"
            style={{ cursor: "pointer" }}
            data-toggle={"tooltip"}
            data-placement="bottom"
            title={"Show / hide existing API key"}
            onClick={() => setShowPassword(!showPassword)}
          />
          <i
            className="fa fa-terminal ml-2"
            aria-hidden="true"
            style={{ cursor: "pointer", color: "var(--info)" }}
            data-toggle={"tooltip"}
            data-placement="bottom"
            title={"Show reference commands"}
            onClick={openModal}
          />
        </div>
      )}

      {showModal.open && (
        <CustomModal
          visible={showModal.open}
          setVisible={dismissModal}
          header={`Steps to use Klusternetes CLI`}
          content={<CLI apiKey={apiKey} account={account} />}
        />
      )}
    </>
  );
};

export default APIKey;
