import React from "react";

import "./Terms.css";

const TermsAndConditions = () => {
  return (
    <div className="terms content">
      <h5>
        <b>1. Introduction</b>
      </h5>
      <p>
        These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”)
        govern your use of our web pages, Services and Software (“<b>Service</b>
        ”) located at{" "}
        <a href="https://www.klusternetes.com">https://www.klusternetes.com</a>
        operated or made available by Loft Labs, Inc. (“<b>Company</b>”, “
        <b>we</b>”, “<b>our</b>”, “<b>us</b>”).
        <br />
        Our Privacy Policy also governs your use of our Service and explains how
        we collect, safeguard and disclose information that results from your
        use of our web pages. Please read it here
        <a href="https://www.klusternetes.com/legal/privacy-policy">
          https://www.klusternetes.com/legal/privacy-policy
        </a>
        .
        <br />
        Your agreement with us includes these Terms and our Privacy Policy (“
        <b>Agreements</b>”). You acknowledge that you have read and understood
        Agreements and agree to be bound by them.
        <br />
        If you do not agree with (or cannot comply with) Agreements, then you
        may not use the Service, but please let us know by emailing at{" "}
        <a href="mailto:support@klusternetes.com">
          support@klusternetes.com
        </a>{" "}
        so we can try to find a solution. These Terms apply to all visitors,
        users and others who wish to access or use our Service.
      </p>
      <h5>
        <b>2. Communications</b>
      </h5>
      <p>
        By creating an Account on our Service, you agree to subscribe to
        newsletters, marketing or promotional materials and other information we
        may send. However, you may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or by emailing
        at{" "}
        <a href="mailto:privacy@klusternetes.com">privacy@klusternetes.com</a>.
      </p>
      <h5>
        <b>3. Purchases</b>
      </h5>
      <p>
        If you wish to purchase any product or service made available through
        our Service (“<b>Purchase</b>”), you may be asked to supply certain
        information relevant to your Purchase including, without limitation,
        your credit card number, the expiration date of your credit card, your
        billing address, and your shipping information.
        <br />
        You represent and warrant that: (i) you have the legal right to use any
        credit card(s) or other payment method(s) in connection with any
        Purchase; and that (ii) the information you supply to us is true,
        correct and complete.
        <br />
        We may employ the use of third party services for the purpose of
        facilitating payment and the completion of Purchases. By submitting your
        information, you grant us the right to provide the information to these
        third parties subject to our Privacy Policy.
        <br />
        We reserve the right to refuse or cancel your order at any time for
        reasons including but not limited to: product or service availability,
        errors in the description or price of the product or service, error in
        your order or other reasons.
        <br />
        We reserve the right to refuse or cancel your order if fraud or an
        unauthorized or illegal transaction is suspected.
      </p>
      <h5>
        <b>4. Subscriptions</b>
      </h5>
      <p>
        Some parts of our Service are billed on a subscription basis (“
        <b>Subscription(s)</b>”). You will be billed in advance on a recurring
        and periodic basis (“<b>Billing Cycle</b>”). Billing cycles are set on a
        monthly basis.
        <br />
        At the end of each Billing Cycle, your Subscription will automatically
        renew under the exact same conditions unless you cancel it or Company
        cancels it. You may cancel your Subscription renewal either through your
        online account management page or by contacting Company customer support
        team.
        <br />
        A valid payment method, including credit card, is required to process
        the payment for your subscription. You shall provide Company with
        accurate and complete billing information including full name, address,
        state, zip code, telephone number, and a valid payment method
        information. By submitting such payment information, you automatically
        authorize Company to charge all Subscription fees incurred through your
        account to any such payment instruments.
        <br />
        Should automatic billing fail to occur for any reason, Companywill issue
        an electronic invoice indicating that you must proceed manually, within
        a certain deadline date, with the full payment corresponding to the
        billing period as indicated on the invoice.
      </p>
      <h5>
        <b>5. Free Trial</b>
      </h5>
      <p>
        Company may, at its sole discretion, offer a Subscription with a free
        trial for a limited period of time (“<b>Free Trial</b>”).
        <br />
        You may be required to enter your billing information in order to sign
        up for Free Trial.
        <br />
        If you do enter your billing information when signing up for Free Trial,
        you will not be charged by Company until Free Trial has expired. On the
        last day of Free Trial period, unless you cancelled your Subscription,
        you will be automatically charged the applicable Subscription fees for
        the type of Subscription you have selected.
        <br />
        At any time and without notice, Company reserves the right to (i) modify
        Terms of Service of Free Trial offer, or (ii) cancel such Free Trial
        offer.
      </p>
      <h5>
        <b>6. Fee Changes</b>
      </h5>
      <p>
        Company, in its sole discretion and at any time, may modify Subscription
        fees for the Subscriptions. Any Subscription fee change will become
        effective at the end of the then-current Billing Cycle.
        <br />
        Company will provide you with a reasonable prior notice of any change in
        Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective.
        <br />
        Your continued use of our Service after a Subscription fee change comes
        into effect constitutes your agreement to pay the modified Subscription
        fee amount.
      </p>
      <h5>
        <b>7. Refunds</b>
      </h5>
      <p>
        Except when required by law, paid Subscription fees are non-refundable.
      </p>
      <h5>
        <b>8. Content</b>
      </h5>
      <p>
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, software, or
        other material (“<b>Content</b>”). You are responsible for Content that
        you post on or through our Service, including its legality, reliability,
        and appropriateness.
        <br />
        By posting Content on or through our Service, You represent and warrant
        that: (i) Content is yours (you own it) and/or you have the right to use
        it and the right to grant us the rights and license as provided in these
        Terms, and (ii) that the posting of your Content on or through our
        Service does not violate the privacy rights, publicity rights,
        copyrights, contract rights or any other rights of any person or entity.
        We reserve the right to terminate the account of anyone found to be
        infringing on a copyright.
        <br />
        You retain any and all of your rights to any Content you submit, post or
        display on or through our Service and you are responsible for protecting
        those rights. We take no responsibility and assume no liability for
        Content you or any third party posts on or through our Service. However,
        by posting Content using our Service you grant us the right and license
        to use, modify, publicly perform, publicly display, reproduce, and
        distribute such Content on and through our Service. You agree that this
        license includes the right for us to make your Content available to
        other users of our Service, who may also use your Content subject to
        these Terms.
        <br />
        Company has the right but not the obligation to monitor and edit all
        Content provided by users.
        <br />
        In addition, Content found on or through this Service are the property
        of Company or used with permission. You may not distribute, modify,
        transmit, reuse, download, repost, copy, or use said Content, whether in
        whole or in part, for commercial purposes or for personal gain, without
        express advance written permission from us.
      </p>
      <h5>
        <b>9. Prohibited Uses</b>
      </h5>
      <p>
        You may use our Service only for lawful purposes and in accordance with
        Terms. You agree not to use Service:
      </p>
      <ul style={{ listStyle: "decimal", paddingLeft: "20px" }}>
        <li>
          In any way that violates any applicable national or international law
          or regulation.
        </li>
        <li>
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content or
          otherwise.
        </li>
        <li>
          To transmit or procure the sending of any advertising or promotional
          material, including any “junk mail”, “chain letter,” “spam,” or any
          other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate our Company, a Company
          employee, another user, or any other person or entity.
        </li>
        <li>
          In any way that infringes upon the rights of others, or in any way is
          illegal, threatening, fraudulent, or harmful, or in connection with
          any unlawful, illegal, fraudulent, or harmful purpose or activity.
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone’s use
          or enjoyment of our Service, or which, as determined by us, may harm
          or offend our Company or users of our Service or expose them to
          liability.
        </li>
      </ul>
      <p>Additionally, you agree not to:</p>
      <ul style={{ listStyle: "decimal", paddingLeft: "20px" }}>
        <li>
          Use our Service in any manner that could disable, overburden, damage,
          or impair our Service or interfere with any other party’s use of our
          Service, including their ability to engage in real time activities
          through our Service.
        </li>
        <li>
          Use any robot, spider, or other automatic device, process, or means to
          access our Service for any purpose, including monitoring or copying
          any of the material on our Service.
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on our
          Service or for any other unauthorized purpose without our prior
          written consent.
        </li>
        <li>
          Use any device, software, or routine that interferes with the proper
          working of our Service.
        </li>
        <li>
          Introduce any viruses, trojan horses, worms, logic bombs, or other
          material which is malicious or technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of our Service, the server on which our Service is
          stored, or any server, computer, or database connected to Service.
        </li>
        <li>
          Attack our Service via a denial-of-service attack or a distributed
          denial-of-service attack.
        </li>
        <li>Take any action that may damage or falsify our Company rating.</li>
        <li>
          Otherwise attempt to interfere with the proper working of our Service.
        </li>
        <li>
          Create multiple accounts, applications, projects or Content to
          simulate or act as a single account, application, project or other
          Content in a manner intended to avoid fees or exceed usage
          limitations.
        </li>
      </ul>
      <h5>
        <b>10. No Use By Minors</b>
      </h5>
      <p>
        Our Service is intended only for access and use by individuals at least
        eighteen (18) years old. By accessing or using any of our Service, you
        warrant and represent that you are at least eighteen (18) years of age
        and with the full authority, right, and capacity to enter into this
        agreement and abide by all of the terms and conditions of these Terms.
        If you are not at least eighteen (18) years old, you are prohibited from
        both the access and usage of our Service.
      </p>
      <h5>
        <b>11. Accounts</b>
      </h5>
      <p>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        our Service.
        <br />
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
        <br />
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
        <br />
        We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders in our sole discretion.
      </p>
      <h5>
        <b>12. Intellectual Property</b>
      </h5>
      <p>
        Our Service and its original content (excluding Content provided by
        users), features and functionality are and will remain the exclusive
        property of Company and its licensors. Our Service is protected by
        copyright, trademark, and other laws of the United States and foreign
        countries. Our trademarks and trade dress may not be used in connection
        with any product or service without the prior written consent of
        Company.
      </p>
      <h5>
        <b>13. Copyright Policy</b>
      </h5>
      <p>
        We respect the intellectual property rights of others. It is our policy
        to respond to any claim that Content posted on our Service infringes on
        the copyright or other intellectual property rights (“
        <b>Infringement</b>”) of any person or entity.
        <br />
        If you are a copyright owner, or authorized on behalf of one, and you
        believe that the copyrighted work has been copied in a way that
        constitutes copyright infringement, please submit your claim via email
        to
        <a href="mailto:support@klusternetes.com">support@klusternetes.com</a>,
        with the subject line: “Copyright Infringement” and include in your
        claim a detailed description of the alleged Infringement. We will
        respond to all such notices, including as required or appropriate by
        removing the infringing material or disabling all links to the
        infringing material.
        <br />
        You may be held accountable for damages (including costs and attorneys'
        fees) for misrepresentation or bad-faith claims on the infringement of
        any Content found on and/or through our Service on your copyright.
      </p>
      <h5>
        <b>14. Error Reporting and Feedback</b>
      </h5>
      <p>
        You may provide us directly at{" "}
        <a href="mailto:support@klusternetes.com">support@klusternetes.com</a>{" "}
        or via the chat on our web site with information and feedback concerning
        errors, suggestions for improvements, ideas, problems, complaints, and
        other matters related to our Service (“<b>Feedback</b>”). You
        acknowledge and agree that: (i) you shall not retain, acquire or assert
        any intellectual property right or other right, title or interest in or
        to the Feedback; (ii) our Company may have development ideas similar to
        the Feedback; (iii) the Feedback does not contain confidential
        information or proprietary information from you or any third party; and
        (iv) our Company is not under any obligation of confidentiality with
        respect to the Feedback. In the event the transfer of the ownership to
        the Feedback is not possible due to applicable mandatory laws, you grant
        our Company and its affiliates an exclusive, transferable, irrevocable,
        free-of-charge, sub-licensable, unlimited and perpetual right to use
        (including copy, modify, create derivative works, publish, distribute
        and commercialize) the Feedback in any manner and for any purpose.
      </p>
      <h5>
        <b>15. Links To Other Web Sites</b>
      </h5>
      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by Company.
        <br />
        Company has no control over, and assumes no responsibility for the
        content, privacy policies, or practices of any third party web sites or
        services. We do not warrant the offerings of any of these
        entities/individuals or their websites.
        <br />
        YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
        LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
        TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
        CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY
        WEB SITES OR SERVICES.
        <br />
        WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
        OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
      </p>
      <h5>
        <b>16. Disclaimer Of Warranty</b>
      </h5>
      <p>
        THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. OUR COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF OUR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
        ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
        <br />
        NEITHER OUR COMPANY NOR ANY PERSON ASSOCIATED WITH OUR COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
        LIMITING THE FOREGOING, NEITHER OUR COMPANY NOR ANYONE ASSOCIATED WITH
        OUR COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR
        ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.
        <br />
        OUR COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.
        <br />
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>
      <h5>
        <b>17. Limitation Of Liability</b>
      </h5>
      <p>
        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF OUR COMPANY HAS BEEN PREVIOUSLY ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE
        IS LIABILITY FOUND ON THE PART OF OUR COMPANY, IT WILL BE LIMITED TO THE
        AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES
        WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT
        ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
        CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT
        APPLY TO YOU.
      </p>
      <h5>
        <b>18. Termination</b>
      </h5>
      <p>
        We may terminate or suspend your account and bar access to our Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of Terms.
        <br />
        If you wish to terminate your account, you may simply discontinue using
        our Service.
        <br />
        All provisions of Terms which by their nature should survive termination
        shall survive termination, including, without limitation, ownership
        provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>
      <h5>
        <b>19. Publicity</b>
      </h5>
      <p>
        Company may include your and/or your company's name and logo on
        Company’s web site and public customer lists, and may publish a press
        release describing your selection to use the Service and/or a written or
        video success story describing your use of the Service.
      </p>
      <h5>
        <b>20. Governing Law</b>
      </h5>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of the <b>State of California-USA</b>
        without regard to its conflict of law provisions.
        <br />
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service and supersede and replace any
        prior agreements we might have had between us regarding our Service.
      </p>
      <h5>
        <b>21. Changes To Our Service</b>
      </h5>
      <p>
        We reserve the right to withdraw or amend our Service, and any service
        or material we provide via our Service, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of our
        Service is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of our Service, or the entire
        Service, to users, including registered users.
      </p>
      <h5>
        <b>22. Amendments To Terms</b>
      </h5>
      <p>
        We may amend these Terms at any time by posting the amended terms on
        this site. It is your responsibility to review these Terms periodically.
        <br />
        Your continued use of our Service following the posting of revised Terms
        means that you accept and agree to the changes. You are expected to
        check this page frequently so you are aware of any changes, as they are
        binding on you.
        <br />
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use our Service.
      </p>
      <h5>
        <b>23. Waiver And Severability</b>
      </h5>
      <p>
        No waiver by our Company of any term or condition set forth in these
        Terms shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        our Company to assert a right or provision under these Terms shall not
        constitute a waiver of such right or provision.
        <br />
        If any provision of these Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of these Terms will continue
        in full force and effect.
      </p>
      <h5>
        <b>24. Acknowledgement</b>
      </h5>
      <p>
        BY USING OUR SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
        THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
      </p>
      <h5>
        <b>25. Contact Us</b>
      </h5>
      <p>
        Please send your feedback, comments, requests for technical support:
        <br />
        By email:{" "}
        <a href="mailto:support@klusternetes.com">support@klusternetes.com</a>
        <br />
        By using the chat on our website:{" "}
        <a href="https://www.klusternetes.com">https://www.klusternetes.com</a>
      </p>
    </div>
  );
};

export default TermsAndConditions;
