import React from "react";
import { NavLink } from "react-router-dom";
// import AppLogo from "../../assets/logo.png"; //colored logo
import AppLogo from "../../assets/logo1.png";
import ShortLogo from "../../assets/short-logo-nobg.png";

const Logo = () => {
  return (
    <>
      <NavLink className="c-sidebar-brand-full" to="/">
        <h4 className="text-light c-sidebar-brand-full">
          <img src={AppLogo} alt="Logo" width={200} />
        </h4>
      </NavLink>
      <NavLink className="c-sidebar-brand-minimized" to="/">
        <h4 className="c-sidebar-brand-minimized">
          <img style={{filter:" brightness(0) invert(1)"}} src={ShortLogo} width={50} alt="" />
        </h4>
      </NavLink>
    </>
  );
};

export default Logo;
