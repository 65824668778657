import { CNav, CNavItem, CNavLink,  CTabContent, CTabPane, CTabs } from '@coreui/react'
import React, {  useRef, useState } from 'react';
import yaml from 'js-yaml';
import YAML from 'yaml'

import "./marketplace.css"
import { flattenObject, objectDifference } from '../../../../../../libs/helper';

import Editor from "@monaco-editor/react";

const EditConfiguration = ({ feature, editedYaml, setEditedYaml, setCheckErr, currentFeature, setCurrentFeature }) => {
    const [activeTab, setActiveTab] = useState("1");
    const [yamlError, setYamlError] = useState(false);

    
   const editorRef = useRef(null);
    const handleEditorOnChange = (editor, monaco) => {
        editorRef.current = editor;
        validateEditorYaml(editorRef.current)
    }
    

    const validateAndEditYaml = (e, values, x) => { 
        try {
            const srcYaml = yaml.load(atob(editedYaml));
            setEditedYaml(btoa(e.target.value))
            const updatedYaml = yaml.load(e.target.value);
            setYamlError(false);

            const difference = objectDifference(srcYaml, updatedYaml);
            const flatObject = flattenObject(difference);

            if (Object.keys(feature.optionalValues).find(x => x === Object.keys(flatObject)[0])) {
                setCurrentFeature({
                    ...currentFeature,
                    optionalValues: {
                        ...currentFeature.optionalValues,
                        [Object.keys(flatObject)[0]]: Object.values(flatObject)[0]
                    }
                })
            }
            else if (Object.keys(feature.values).find(x => x === Object.keys(flatObject)[0])) {
                setCurrentFeature({
                    ...currentFeature,
                    values: {
                        ...currentFeature.values,
                        [Object.keys(flatObject)[0]]: Object.values(flatObject)[0]
                    }
                })
            }
        }
        catch (err) {
            setYamlError({
                name: err.name || "Error parsing yaml",
                message: err.message.split(" | ") || err.reason
            })
        }
    }

    const validateEditorYaml = (e) => {
        try {   
            setEditedYaml(btoa(e))
            const srcYaml = yaml.load(atob(editedYaml)); 
            const updatedYaml = yaml.load(e);
            setYamlError(false);

            const difference = objectDifference(srcYaml, updatedYaml);
            const flatObject = flattenObject(difference);
            
            if (Object.keys(feature.optionalValues).find(x => x === Object.keys(flatObject)[0])) {
                setCurrentFeature({
                    ...currentFeature,
                    optionalValues: {
                        ...currentFeature.optionalValues,
                        [Object.keys(flatObject)[0]]: Object.values(flatObject)[0]
                    }
                })
            }
            else if (Object.keys(feature.values).find(x => x === Object.keys(flatObject)[0])) {
                setCurrentFeature({
                    ...currentFeature,
                    values: {
                        ...currentFeature.values,
                        [Object.keys(flatObject)[0]]: Object.values(flatObject)[0],
                    }
                })
            }
        setCheckErr(false)
        }
        catch (err) {
            setYamlError({
                name: err.name || "Error parsing yaml",
                message: err.message.split(" | ") || err.reason
            });
            setCheckErr(true)
        }
    }

    const setYamlPropertyValue = (sourceYaml, path, value) => {
        var schema = sourceYaml;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
    }

    const updateYamlByForm = async (label, defaultValue, newValue, e) => {
        try {
            let yamlDoc = YAML.parse(atob(editedYaml));
            console.log(yamlDoc)
            setYamlPropertyValue(yamlDoc, label, newValue)
            validateAndEditYaml({ target: { value: YAML.stringify(yamlDoc) } }, e, e)
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const validateAndGetCurrentRecord = (label, currentValue, index) => {
        return <div className={`row d-flex align-items-center`} key={index} >
            <label className="col col-form-label" style={{ flexGrow: 1 }}>
                {label}
            </label>
            <div className="col">
                {typeof currentValue === "boolean"
                    ? <select className="form-select"
                        onLoad={(e) => updateYamlByForm(label, JSON.parse(currentValue), JSON.parse(e.target.value), e)}
                        onChange={(e) => updateYamlByForm(label, JSON.parse(currentValue), JSON.parse(e.target.value), e)}>
                        <option value={true} selected={JSON.parse(currentValue) === true}>{"true"}</option>
                        <option value={false} selected={JSON.parse(currentValue) === false}>{"false"}</option>
                    </select>
                    :
                    <input type="text"
                    //  defaultValue={currentValue}
                        style={{ minWidth: '40vmin' }}
                        placeholder='Edit yaml value'
                        name={label}
                        value={currentValue}
                        onLoad={(e) => updateYamlByForm(label, currentValue, e.target.value, e)}
                        onChange={(e) => updateYamlByForm(label, currentValue, e.target.value, e)} />
                }
            </div>
        </div>
    }

    console.log(currentFeature.optionalValues);
    console.log(currentFeature.values);
    return <div className="mrk__config__tabs">
        <CTabs activeTab={activeTab} >
            <CNav variant="tabs">
                <CNavItem data-tab="1" onClick={() => setActiveTab("1")}>
                    <CNavLink active={activeTab === "1"}>
                        Edit Form
                    </CNavLink>
                </CNavItem>
                <CNavItem data-tab="2" onClick={() => setActiveTab("2")}>
                    <CNavLink active={activeTab === "2"}>
                        Edit Yaml
                    </CNavLink>
                </CNavItem>
            </CNav>
            <CTabContent>
                <CTabPane data-tab={"1"}>
                    {activeTab === "1" &&
                        <>
                            <p className="text-success pt-2 font-weight-bold">{`Default / Required configuration settings for the yaml / helm chart.`}</p>
                            <p className="text-info font-italic">{`Changes here will automatically update the yaml in next tab.`}</p>
                            {Object.keys(currentFeature.values).length > 0 &&
                                <>
                                    <h6 className="mt-2 p-2 bg-warning">Mandatory configuration settings</h6>
                                    <div className="d-flex flex-column valuesForm">
                                        {Object.keys(currentFeature.values).map((key, idx) => {
                                            return validateAndGetCurrentRecord(key, currentFeature.values[key], idx)
                                        })}
                                    </div>
                                </>
                            }
                            {Object.keys(currentFeature.optionalValues).length > 0 &&
                                <>
                                    <h6 className="mt-2 p-2 bg-warning">Optional configuration settings</h6>
                                    <div className="d-flex flex-column valuesForm">
                                        {Object.keys(currentFeature.optionalValues).map((key, idx) => {
                                            return validateAndGetCurrentRecord(key, currentFeature.optionalValues[key], idx)
                                        })}
                                    </div>
                                </>
                            }
                        </>
                    }
                </CTabPane>
                <CTabPane data-tab={"2"} className="d-flex flex-column">
                    {activeTab === "2" &&
                        <>
                            {yamlError &&
                                <div>
                                    {yamlError.message.map((msg, i) => {
                                        return <p key={i} className="text-danger yamlErrorText">{msg}</p>
                                    })}
                                </div>
                            }

                             
                                <Editor
                                    height="90vh"
                                    defaultLanguage="yaml"
                                    theme="vs-dark"
                                    value={atob(editedYaml)}
                                    onChange={handleEditorOnChange}
                                />
                             
                        </>
                    }
                </CTabPane>
            </CTabContent>
        </CTabs>
    </div>

}

export default EditConfiguration
