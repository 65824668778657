import React, { useEffect, useState } from "react";
import { FirebaseAuth } from "../FirebaseAuth/firebase";
import { Form } from "../Form";

const StepContent = ({
  divId,
  content,
  doneFunction,
  nextFunction,
  previousFunction,
  showNext,
  showPrevious,
  isLast,
  showForm,
  handleFormSubmit,
  ClusterName,
  newAddonName,
  RepoURL,
  inSubmit,
  currentStep,
  calledFrom,
  clusterSizeIndex,
  newClusterID,
  addonCategory,
  selectedChart,
  selectedVersion,
  getHelmRepoYaml,
}) => {
  const [clstrStatus, setClstrStatus] = useState("");
  const clustersRef = FirebaseAuth.firestore().collection("clusters");
  newClusterID &&
    clustersRef.doc(newClusterID).onSnapshot((querySnapshot) => {
      setClstrStatus(querySnapshot.data()?.status);
    });
  const disabled =
    calledFrom === "newCluster"
      ? ClusterName?.hasError ||
        ClusterName?.value === null ||
        inSubmit ||
        clusterSizeIndex === undefined
      : newAddonName?.hasError ||
        newAddonName?.value === null ||
        addonCategory?.value === null ||
        addonCategory?.hasError ||
        inSubmit;
  const formSubmit =
    calledFrom === "newCluster"
      ? currentStep === 2
      : currentStep === 2 && RepoURL.value && selectedChart && selectedVersion
  return (
    <div id={divId} className="content">
      {showForm ? (
        <Form
          handleSubmit={handleFormSubmit}
          disabled={
            calledFrom === "newCluster"
              ? ClusterName.hasError || ClusterName.value === null || inSubmit
              : newAddonName.hasError || newAddonName.value === null || inSubmit
          }
          inSubmit={inSubmit}
          enableDefaultButtons={formSubmit}
        >
          {content}
        </Form>
      ) : (
        content
      )}
      {showNext && (
        <button
          id="btnNext"
          className="btn btn-primary"
          onClick={nextFunction}
          disabled={disabled}
        >
          Next
        </button>
      )}
      {showPrevious && (
        <button
          className="btn btn-primary"
          style={{
            position: "relative",
            bottom: "-4em",
          }}
          onClick={previousFunction}
        >
          Previous
        </button>
      )}
      {isLast && (
        <button
          id="btnDone"
          className="btn btn-primary"
          onClick={doneFunction}
          // disabled={clstrStatus !== "READY" && calledFrom == "newCluster"}
        >
          Done
        </button>
      )}
    </div>
  );
};

export default StepContent;
