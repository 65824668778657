import React from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

const CustomModal = ({
  visible,
  setVisible,
  header,
  content,
  successText,
  onSuccess,
  buttonDisabled,
}) => {
  return (
    <CModal
      className={`customModal d-flex align-items-center ${visible && "show"}`}
    >
      <CModalHeader>
        <CModalTitle>{header}</CModalTitle>
      </CModalHeader>

      <CModalBody>{content}</CModalBody>

      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => setVisible({ open: false, data: null })}
        >
          {`Close`}
        </CButton>

        {successText && (
          <CButton color="primary" onClick={onSuccess} disabled={buttonDisabled}>
            {successText}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};

export default CustomModal;
