import React, { useEffect } from "react";
import { IsLoginUserFromZelarSoft } from "../../../libs/helper";
import SidebarLink from "../SidebarLink";

const AppMenu = () => {
  useEffect(() => {
    document.querySelectorAll(".c-sidebar").forEach((element) => {
      window.coreui.Sidebar._sidebarInterface(element);
    });
  });

  return (
    <ul className="c-sidebar-nav ps ps--active-y">
      <li className="c-sidebar-nav-title">Application</li>
      <li className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/home") ? " active" : "")
          }
          to="/home"
        >
          <i className="c-sidebar-nav-icon fa fa-th-large"></i> My Accounts
        </SidebarLink>
      </li>
      <li className="c-sidebar-nav-title">User</li>
      <li className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/user/profile")
              ? " active"
              : "")
          }
          to="/user/profile"
        >
          <i className="c-sidebar-nav-icon fa fa-user"></i> Profile
        </SidebarLink>
      </li>
      <li className="c-sidebar-nav-item">
        <SidebarLink
          className={
            "c-sidebar-nav-link" +
            (window.location.pathname.startsWith("/user/log") ? " active" : "")
          }
          to="/user/log"
        >
          <i className="c-sidebar-nav-icon fa fa-list"></i> Activity Logs
        </SidebarLink>
      </li>
      <li className="c-sidebar-nav-item">
        <SidebarLink
          className="c-sidebar-nav-link"
          to={{ pathname: "https://klusternetes.readme.io/" }}
          target="_blank"
        >
          <i className="c-sidebar-nav-icon fa fa-link"></i>Docs
        </SidebarLink>
      </li>
      {IsLoginUserFromZelarSoft() && (
        <li className="c-sidebar-nav-item">
          <SidebarLink
            className={
              "c-sidebar-nav-link" +
              (window.location.pathname.startsWith("/datacenter/view")
                ? " active"
                : "")
            }
            to="/datacenter/view"
          >
            <i className="c-sidebar-nav-icon fa fa-plus-circle"></i>
            Data Centers
          </SidebarLink>
        </li>
      )}
    </ul>
  );
};

export default AppMenu;
