import { FirebaseAuth } from "../components/FirebaseAuth/firebase";
import { transform, isEqual, isArray, isObject } from 'lodash';

export const IsLoginUserFromZelarSoft = () => {
  try {
    const currentUser = FirebaseAuth.auth().currentUser || null;
    return (
      currentUser.emailVerified && currentUser.email.endsWith("@zelarsoft.com")
    );
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const objectDifference = (origObj, newObj) => {
  const changes = (newObj, origObj) => {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

export const flattenObject = (obj, roots = [], sep = '.') => {
  return Object.keys(obj).reduce((memo, prop) => Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]' ? flattenObject(obj[prop], roots.concat([prop]), sep) : { [roots.concat([prop]).join(sep)]: obj[prop] }), {})
}
