import React, { createContext, useEffect, useState } from 'react'
import { firebase } from './firebase';

export const RemoteConfigContext = createContext({});
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = 10000; // 10secs
remoteConfig.defaultConfig = {
    "manualAcceptanceOfUsers": false
}

export const RemoteConfigProvider = ({ children }) => {

    const [flags, setFlags] = useState();

    useEffect(() => {
        remoteConfig.fetchAndActivate()
            .then(() => {
                return remoteConfig.getAll();
            }).then((remoteFlags) => {
                const newFlags = {
                    ...flags,
                };
                for (const [key, config] of Object.entries(remoteFlags)) {
                    newFlags[key] = config.asBoolean();
                }
                setFlags(newFlags);
            })
            .catch(e => {
                setFlags(remoteConfig.defaultConfig);
            })
    }, [flags])

    return (
        <RemoteConfigContext.Provider value={{ flags }}>
            {children}
        </RemoteConfigContext.Provider>
    );
}