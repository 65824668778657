import {
  FirebaseAuth,
  LogAnalyticsEvent,
} from "../../components/FirebaseAuth/firebase";
import { log, SIGN_IN, SIGN_OUT, UPDATE_USERNAME } from "../log";

export const userSignIn = (flags, callback) => {
  var dt = new Date();
  const Firestore = FirebaseAuth.firestore();
  const currentUser = FirebaseAuth.auth().currentUser;

  console.log("Remote Config", flags);

  const userDocRef = Firestore.collection("users").doc(currentUser.uid);
  userDocRef.get().then((doc) => {
    if (doc.exists) {
      // update user document
      userDocRef
        .set(
          {
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
            lastLoginTime: dt,
            accepted: currentUser.accepted || !flags.manualAcceptanceOfUsers,
          },
          { merge: true }
        )
        .then(() => {
          callback(true);
        })
        .catch((err) => {
          console.log(err);
          callback(false);
        });
      LogAnalyticsEvent("user_relogin", {
        user_name: currentUser.displayName,
        user_email: currentUser.email || "",
        last_login: dt,
      });
    } else {
      // create user document
      userDocRef
        .set({
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
          creationTime: dt,
          lastLoginTime: dt,
          accepted: !flags.manualAcceptanceOfUsers,
        })
        .then(() => {
          callback(true);
        })
        .catch((err) => {
          console.log(err);
          callback(false);
        });

      LogAnalyticsEvent("user_signup", {
        user_name: currentUser.displayName,
        user_email: currentUser.email || "",
        creation_time: dt,
      });
    }
  });
  log(SIGN_IN);
};

export const userSignOut = () => {
  log(SIGN_OUT, (result) => {
    // wait for log is successfully written before signing out
    if (result) {
      FirebaseAuth.auth().signOut();
    }
  });
};

export const userUpdateName = () => {
  const Firestore = FirebaseAuth.firestore();
  const currentUser = FirebaseAuth.auth().currentUser;

  const userDocRef = Firestore.collection("users").doc(currentUser.uid);
  userDocRef.set(
    {
      displayName: currentUser.displayName,
    },
    { merge: true }
  );
  log(UPDATE_USERNAME);
};
