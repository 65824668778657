import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDrag } from "react-use-gesture";

import { FirebaseAuth } from "../../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../../components/Loader";
import { BreadcrumbContext } from "../../../../../components/Breadcrumb";
import ClustersView from "./ClustersView";
import { AuthContext } from "../../../../../components/FirebaseAuth";

import "./Clusters.css";
import { CCard, CCardBody, CCardHeader, CCardText } from "@coreui/react";
const Clusters = () => {
  const title = "Clusters";

  const { userData } = useContext(AuthContext);
  const userId = userData.currentAccount.owner;

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [loading, setLoading] = useState(true);
  const [clusters, setClusters] = useState([]);
  /* eslint-disable */
  const [consoleModal, setConsoleModal] = useState({
    show: false,
  });
  const mountedRef = useRef(true);

  const deleteClusterFromView = async (clusterId) => {
    setClusters(clusters.filter((x) => x.id !== clusterId));
  };

  const getClusters = () => {
    setLoading(true);
    let records = [];
    const clustersRef = FirebaseAuth.firestore().collection("clusters");
    let query = clustersRef.where(
      "accountId",
      "==",
      userData.currentAccount.id
    );

    query.get().then((clustersSnapshots) => {
      if (!mountedRef.current) return null;
      clustersSnapshots.forEach((cluster) => {
        if (new Date(cluster.data().expiry?.seconds * 1000) < new Date()) {
          return false;
        }

        records.push({
          id: cluster.id,
          name: cluster.data().name,
          createdDate: new Date(cluster.data().createdDate.seconds * 1000),
          clusterValidity_days:
            (new Date(cluster.data().expiry?.seconds * 1000) -
              new Date(cluster.data().createdDate.seconds * 1000)) /
            (1000 * 86400),
          expiresIn_days:
            Math.ceil(
              (new Date(cluster.data().expiry?.seconds * 1000) - new Date()) /
                (1000 * 86400)
            ) === 0
              ? 0
              : Math.ceil(
                  (new Date(cluster.data().expiry?.seconds * 1000) -
                    new Date()) /
                    (1000 * 86400)
                ) || "never",
          description: cluster.data().description,
          version: cluster.data().version,
          status: cluster.data().status,
          size: cluster.data().size,
          kubeconfig: cluster.data().kubeconfig,
          kubeconfigDlink: cluster.data().kubeconfigDlink || "",
          features: cluster.data().features,
          owner:cluster.data().owner
        });
      });
      query.onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const currentCluster =
            records.length > 0 && records.find((x) => x.id === change.doc.id);
          currentCluster &&
            setClusters([
              ...records.filter((x) => x.id !== currentCluster.id),
              {
                ...currentCluster,
                status: change.doc.data().status,
                kubeconfig: change.doc.data().kubeconfig,
                kubeconfigDlink: change.doc.data().kubeconfigDlink,
                features:change.doc.data().features
              },
            ]);
        });
      });
      setClusters(records);
      setLoading(false);
    });
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [display, setDisplay] = useState("block");
  const [isMinimised, setIsMinimised] = useState(false);
  const bindPosition = useDrag((params) => {
    setPosition({ x: params.offset[0], y: params.offset[1] });
  });
  const DEFAULT_STYLES = {
    position: "absolute",
    width: window.innerWidth / 2.5,
    top: position.y,
    left: position.x,
    backgroundColor: "snow",
    display: display,
    zindex: "10",
  };
  const MINIMISE_STYLES = {
    position: "fixed",
    background: "snow",
    width: 400,
    bottom: -20,
    right: 0,
    display: display,
  };
  const terminalSizeHandler = () => {
    setIsMinimised(!isMinimised);
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Clusters",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    getClusters();
    return () => {
      mountedRef.current = false;
    };
  }, [setBreadcrumb]);

  return (
    <>
      <div className="container-fluid overviewClass">
        <div className="animated fadeIn">
          {clusters.length > 0 ? (
            <>
              <div className="text-right mb-3">
               
                <Link to="new-cluster" className="btn btn-primary">
                  <i className="fa fa-plus"></i> Add Cluster
                </Link>
              </div>

              <ClustersView
                clusters={clusters}
                deleteClusterFromView={deleteClusterFromView}
              />
            </>
          ) : (
            <>
              {loading ? (
                <Loader text="loading clusters..."></Loader>
              ) : (
                <Redirect to="new-cluster"></Redirect>
              )}
            </>
          )}
        </div>
      </div>
      {consoleModal.show && (
        <CCard
          {...bindPosition()}
          className="terminal"
          style={isMinimised ? MINIMISE_STYLES : DEFAULT_STYLES}
        >
          <CCardHeader
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <CCardText>K8S Terminal</CCardText>
            <div>
              <button
                className="btn btn-dark active"
                onClick={() => terminalSizeHandler()}
                style={{ margin: "10px" }}
              >
                {!isMinimised ? (
                  <i className="fa fa-window-minimize"></i>
                ) : (
                  <i className="fa fa-window-restore" aria-hidden="true"></i>
                )}
              </button>

              <button
                className="btn btn-dark active"
                onClick={() => setDisplay("none")}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </CCardHeader>
          <CCardBody
            style={isMinimised ? { display: "none" } : { display: "block" }}
          >
            <iframe
              id="iTerminal"
              title={"KC Terminal"}
              src={`https://u-${userId}.kourier-system.knts.app`}
              sandbox="allow-scripts"
              frameBorder="0"
              width="100%"
              height="500"
              style={{ overflow: "hidden" }}
            />
          </CCardBody>
        </CCard>
      )}
    </>
  );
};

export default Clusters;
