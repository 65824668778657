import React from "react";

const AccountOverview = ({ account, accountPlans, clusters, clusterResource, currentAccountUsed, setCurrentAccountUsed }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end">
        Total :
        {accountPlans &&
          accountPlans
            .filter((x) => x.accountId === account.id)?.[0]
            ?.planDetails?.map((ftr, i) => (
              <p key={i} className="px-2">
                {ftr}
              </p>
            ))}
      </div>
      {currentAccountUsed && (
        <div className="d-flex justify-content-end">
          Used :<p className="px-2">{currentAccountUsed.sizes.CPU} CPU</p>
          <p className="px-2">{currentAccountUsed.sizes.RAM}GB RAM</p>
          <p className="px-2">{currentAccountUsed.sizes.STORAGE}GB HDD</p>
        </div>
      )}
    </div>
  );
};

export default AccountOverview;
