import React, { useState, useContext, useEffect, useRef } from "react";
import {
  FirebaseAuth,
  CloudFunctions,
  LogAnalyticsEvent,
} from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import Loader from "../../../../components/Loader";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Alert from "../../../../components/Alert";
import { countries } from "../../../../inc/country.json";
import { Field } from "../../../../components/Form";
import { ACCOUNT_PLANS } from "../../../../constants/clusters";

const Plans = () => {
  const title = "Select a Plan";

  const { userData, authUser } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const mountedRef = useRef(true);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [datacenters, setDatacenters] = useState([]);
  const [selectedDatacenter, setSelectedDatacenter] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState({ id: 0 });
  const [cardError, setCardError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(null);
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(null);
  const [disableFreePlan, setDisableFreePlan] = useState(false);
  const [showNoDatacenters, setShowNoDatacenters] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    setLoading(true);

    const accountsQuery = FirebaseAuth.firestore()
      .collection("accounts")
      .where("access", "array-contains", FirebaseAuth.auth().currentUser.uid);
    accountsQuery.get().then((accountSnapshots) => {
      if (!mountedRef.current) return null;
      accountSnapshots.forEach(async (account) => {
        if (account.data().plan === ACCOUNT_PLANS["starter"]) {
          setDisableFreePlan(true);
        }
      });
    });
    return () => {
      mountedRef.current = false;
    };
  }, [userData, setBreadcrumb, title]);
  /* eslint-disable */
  useEffect(() => {
    // Query only the KNTS managed and login user owned datacenters for plans subscription
    FirebaseAuth.firestore()
      .collection("datacenters")
      .where("managed", "==", "KNTS")
      .get()
      .then((datacenterSnapshot) => {
        const datacenters = [];
        if (!datacenterSnapshot.empty) {
          datacenterSnapshot.forEach((datacenter) => {
            datacenters.push({
              id: datacenter.id,
              name: datacenter.data().name,
            });
          });
          setDatacenters((prevState) => [...prevState, ...datacenters]);
          // console.log(datacenters?.[0]);
        } else {
          setShowNoDatacenters(true);
        }
      });

    FirebaseAuth.firestore()
      .collection("datacenters")
      .where("owner", "==", authUser.user.uid)
      .get()
      .then((datacenterSnapshot) => {
        const datacenters = [];
        if (!datacenterSnapshot.empty) {
          datacenterSnapshot.forEach((datacenter) => {
            datacenters.push({
              id: datacenter.id,
              name: datacenter.data().name,
            });
          });
          setDatacenters((prevState) => [...prevState, ...datacenters]);
          // console.log(datacenters?.[0]);
        } else {
          setShowNoDatacenters(true);
        }
      });
  }, []);

  useEffect(() => {
    if (datacenters.length > 0) {
      setSelectedDatacenter(datacenters?.[0]);
    }
  }, [datacenters]);

  useEffect(() => {
    // console.log(selectedDatacenter)
    if (selectedDatacenter) {
      setShowNoDatacenters(false);
      // re-query the current datacenter and plans for the selected datacenter
      async function getPlansByDatacenter() {
        setLoading(true);
        const planDetails = [];
        const currentDatacenter = await FirebaseAuth.firestore()
          .collection("datacenters")
          .doc(selectedDatacenter.id)
          .get();

        Object.entries(currentDatacenter.data().plans)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map((plan) => {
            planDetails.push({
              ...plan[1],
              id: plan[0],
            });
          });

        setPlans(planDetails);
        setLoading(false);
      }

      getPlansByDatacenter();
    }
  }, [selectedDatacenter]);

  const subcribe = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setErrorMessage(null);

    let hasError = false;
    let paymentMethodId = "";

    if (selectedPlan.price !== 0) {
      if (country === "") {
        setCountryError("Please select a country.");
        hasError = true;
      }

      if (state === "" && countries[country] && countries[country].states) {
        setStateError("Please select a state.");
        hasError = true;
      }

      setCardError(null);

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setCardError(error.message);
        hasError = true;
      } else {
        paymentMethodId = paymentMethod.id;
      }
    }

    if (!hasError) {
      console.log(selectedDatacenter);
      const createSubscription =
        CloudFunctions.httpsCallable("createSubscription");
      createSubscription({
        planId: selectedPlan.id,
        accountId: userData.currentAccount.id,
        paymentMethodId: paymentMethodId,
        billing: {
          country: country,
          state: state,
        },
        datacenter: selectedDatacenter.id,
      })
        .then((res) => {
          // physical page load to reload the account data
          document.location = "/account/" + userData.currentAccount.id + "/";
          LogAnalyticsEvent("account_subscription", {
            accountId: userData.currentAccount.id,
            plan: selectedPlan.id,
            datacenter: selectedDatacenter.name,
            user_name: FirebaseAuth.auth().currentUser.displayName || "",
            user_email: FirebaseAuth.auth().currentUser.email || "",
          });
        })
        .catch((err) => {
          setProcessing(false);
          setErrorMessage(err.message);
        });
    } else {
      setProcessing(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          <div className="card-deck mb-3">
            {showNoDatacenters && datacenters.length < 1 ? (
              <div className="card-body text-danger">
                No datacenters configured for this user
              </div>
            ) : (
              <div className="card">
                <div className="card-header text-center">
                  <h3>{title}</h3>
                  <Field label="Datacenter">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setSelectedDatacenter({
                          id: e.target.value,
                          name: e.target.innerText,
                        })
                      }
                    >
                      {datacenters.map((l, i) => {
                        return (
                          <option key={i} value={l.id}>
                            {l.name}
                          </option>
                        );
                      })}
                    </select>
                  </Field>
                </div>
                <div className="card-body">
                  {userData.currentAccount.owner === authUser.user.uid ? (
                    <>
                      {errorMessage !== null && (
                        <Alert
                          type="danger"
                          message={errorMessage}
                          dismissible={true}
                          onDismiss={() => setErrorMessage(null)}
                        ></Alert>
                      )}
                      {plans.length > 0 ? (
                        <div className="row justify-content-md-center">
                          <div className="col col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="card-deck mb-5 text-center">
                              {plans
                                .sort((a, b) => a.ramLimit - b.ramLimit)
                                .map((plan, i) => (
                                  <PlanCard
                                    disableFreePlan={disableFreePlan}
                                    plan={plan}
                                    key={i + plan.id}
                                    plans={plans}
                                    setSelectedPlan={setSelectedPlan}
                                    selectedPlan={selectedPlan}
                                  />
                                ))}
                            </div>

                            {selectedPlan.id !== 0 &&
                              selectedPlan.price > 0 && (
                                <div className="card-deck">
                                  <div className="card mb-4">
                                    <div className="card-header text-center">
                                      <h3>Billing Details</h3>
                                    </div>
                                    <div className="card-body">
                                      <div className="form-group row">
                                        <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                          <b>Country/Territory</b>
                                        </label>
                                        <div className="col-lg-9 mt-2">
                                          {countryError !== null && (
                                            <Alert
                                              type="danger"
                                              message={countryError}
                                              dismissible={true}
                                              onDismiss={() =>
                                                setCountryError(null)
                                              }
                                            ></Alert>
                                          )}
                                          <select
                                            className="form-control"
                                            defaultValue={country}
                                            onChange={(e) => {
                                              const countryCode =
                                                e.target.selectedOptions[0]
                                                  .value;
                                              setCountry(countryCode);
                                              setState("");
                                              setCountryError(null);
                                            }}
                                          >
                                            <option value="">
                                              -- Select a country --
                                            </option>
                                            {Object.keys(countries).map(
                                              (countryCode) => (
                                                <option
                                                  value={countryCode}
                                                  key={countryCode}
                                                >
                                                  {countries[countryCode].name}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      {countries[country] &&
                                        countries[country].states && (
                                          <div className="form-group row">
                                            <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                              <b>State/Province</b>
                                            </label>
                                            <div className="col-lg-9 mt-2">
                                              {stateError !== null && (
                                                <Alert
                                                  type="danger"
                                                  message={stateError}
                                                  dismissible={true}
                                                  onDismiss={() =>
                                                    setStateError(null)
                                                  }
                                                ></Alert>
                                              )}
                                              <select
                                                className="form-control"
                                                defaultValue={state}
                                                onChange={(e) => {
                                                  setState(
                                                    e.target.selectedOptions[0]
                                                      .value
                                                  );
                                                  setStateError(null);
                                                }}
                                              >
                                                <option value="">
                                                  -- Select a state --
                                                </option>
                                                {Object.keys(
                                                  countries[country].states
                                                ).map((stateCode) => (
                                                  <option
                                                    value={stateCode}
                                                    key={stateCode}
                                                  >
                                                    {
                                                      countries[country].states[
                                                        stateCode
                                                      ]
                                                    }
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>
                                        )}
                                      <div className="form-group row mb-0">
                                        <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                          <b>Credit/Debit Card</b>
                                        </label>
                                        <div className="col-lg-9 mt-2">
                                          {cardError !== null && (
                                            <Alert
                                              type="danger"
                                              message={cardError}
                                              dismissible={true}
                                              onDismiss={() =>
                                                setCardError(null)
                                              }
                                            ></Alert>
                                          )}
                                          <div className="form-control">
                                            <CardElement
                                              options={CARD_ELEMENT_OPTIONS}
                                            ></CardElement>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {selectedPlan.id !== 0 && (
                              <button
                                id="btnSubscribe"
                                type="button"
                                className="btn btn-lg btn-block btn-primary"
                                disabled={
                                  selectedPlan.id === 0 || processing
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  subcribe(e);
                                }}
                              >
                                {processing ? (
                                  <Loader text="Please wait while subscription being processed..."></Loader>
                                ) : (
                                  <>Subscribe</>
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          {loading ? (
                            <Loader text="loading plans..."></Loader>
                          ) : (
                            <div>No plan is found</div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Alert
                      type="danger"
                      message="Access Denied."
                      dismissible={false}
                    ></Alert>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const PlanCard = ({
  plan,
  i,
  selectedPlan,
  plans,
  setSelectedPlan,
  disableFreePlan,
}) => {
  const disableCard = disableFreePlan && plan.name === ACCOUNT_PLANS["starter"];

  return (
    <div
      className="card"
      data-toggle={`${disableCard ? "tooltip" : ""}`}
      data-placement="top"
      title={`${disableCard ? "You have used up your free plan" : ""}`}
    >
      <div className="card-header">
        <h4 className="my-0 font-weight-normal">{plan.name}</h4>
        <h1 className="card-title">
          ${plan.price}
          <small className="text-muted">/{plan.paymentCycle}</small>
        </h1>
      </div>
      <div className="card-body">
        <ul className="list-unstyled mt-3 mb-4">
          {plan.features.map((feature, i) => (
            <li key={i}>
              <i className="fa fa-check text-success"></i> {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="card-footer bg-white">
        {disableCard ? (
          <button
            type="button"
            disabled
            style={{
              pointerEvents: "none",
              color: "lightgrey",
              borderColor: "lightgrey",
            }}
            className={"btn btn-block btn-outline-success"}
          >
            Select
          </button>
        ) : (
          <button
            id={`btn${plan.id.replaceAll(" ", "")}`}
            type="button"
            className={
              plan.id !== selectedPlan.id
                ? "btn btn-block btn-outline-success"
                : "btn btn-block btn-success"
            }
            onClick={() => {
              for (let i = 0; i < plans.length; i++) {
                if (plans[i].id === plan.id) {
                  setSelectedPlan(plan);
                }
              }
            }}
          >
            {plan.id === selectedPlan.id && (
              <>
                <i className="fa fa-check"></i>{" "}
              </>
            )}
            {plan.id !== selectedPlan.id ? "Select" : "Selected"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Plans;
